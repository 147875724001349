import React, { Fragment } from "react";
import {
  Typography,
  withStyles,
  AppBar,
  Toolbar,
  Dialog,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { withRouter } from "react-router-dom";

import { Close } from "@material-ui/icons";
import { connect } from "react-redux";
import { sendEvent, getSessionName } from "../../helpers/GaProvider";

import { headerHeightFeedback } from "./heights";

import {
  submitReflectFeedback,
  updateReflectFeedback,
  clearReflectFeedback,
  fetchActionPlanDetail,
} from "../../../actions";
import { Colors } from "../../themes/Colors";
import FeedbackThumbs from "./FeedbackThumbs";
import { AB_GROUP_B } from "../../../constants";

import i18n from "../../../i18n/i18next";
import vaultApi from "../../../apis/vaultApi";
import { TransitionUp } from "../../helpers/Transition";

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  grow: {
    flexGrow: 1,
    "& img": {
      marginRight: theme.spacing(2),
      width: "25px",
    },
  },
  dialogContent: {},
  appBarTop: {
    background: theme.palette.primary.mainGradient,
  },
  appBarTopAlt: {
    background: Colors[0].main,
    height: 120,
  },
  typo: {
    fontWeight: 700,
    marginLeft: 10,
  },
  padded: {
    padding: theme.spacing(3),
  },
  feedbackHeader: {
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
  },
  feedbackHeaderAlt: {
    width: "100vw",
    display: "block",
    justifyContent: "space-between",
    height: 120,
  },
  topBarAlt: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "flex-start",
    alignItems: "flex-start",
  },
  feedbackStepImage: {
    backgroundColor: "#F6FAFD",
    textAlign: "center",
    padding: theme.spacing(2),
  },
});

class FeedbackB extends React.Component {
  state = {
    open: false,
  };

  componentDidUpdate() {
    this.openFeedback();
  }

  openFeedback = () => {
    const { location } = this.props;
    const { open } = this.state;

    if (
      location.pathname.includes("feedback") &&
      !location.pathname.includes("done")
    ) {
      if (!open) {
        this.setState({ open: true });
      }
    } else if (open) {
      this.setState({ open: false });
    }
  };

  handleSendFeedbackButton = ({ selected }) => {
    const { match, softLogin, fetchActionPlanDetail } = this.props;
    const { stepId, actionPlanId, userId, authToken } = match.params;

    vaultApi
      .post("/tip_helpfulness", {
        assignment_id: softLogin.activeChild.currentApa.apaId,
        step: stepId,
        value: selected,
      })
      .then(() => {
        this.handleFeedbackFinished();
        fetchActionPlanDetail(actionPlanId, userId, authToken);
      });
  };

  stepBack = () => {
    const { match, history } = this.props;
    const { actionPlanId, userId, stepId, authToken } = match.params;

    history.push(
      `/family-companion/action-plans/${actionPlanId}/${stepId}/${userId}/${authToken}`
    );
  };

  handleFeedbackCancel = () => {
    const { match, history, softLogin } = this.props;
    const { actionPlanId, userId, stepId, feedbackStep, authToken } =
      match.params;

    const sessionName = getSessionName(softLogin);

    sendEvent(
      `Feedback Canceled: step ${feedbackStep}`,
      `{apID: ${actionPlanId}, apStep: ${stepId}, userID: ${userId}, sessionName: ${sessionName}}`
    );
    history.push(
      `/family-companion/action-plans/${actionPlanId}/${stepId}/${userId}/${authToken}`
    );
  };

  handleFeedbackFinished() {
    const { match, history } = this.props;
    const { actionPlanId, userId, stepId, authToken } = match.params;

    // clearReflectFeedback();
    history.push(
      `/family-companion/action-plans/${actionPlanId}/${stepId}/${userId}/${authToken}/done`
    );
  }

  render() {
    const { classes, windowHeight, match } = this.props;
    const { userId, feedbackStep } = match.params;
    const apStep = match.params.stepId;
    const { open } = this.state;

    return (
      <Fragment>
        <Dialog fullScreen open={open} TransitionComponent={TransitionUp}>
          <div className={classes.feedbackHeader}>
            <AppBar position="static" className={classes.appBarTop}>
              <Toolbar>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.grow}
                >
                  <img src="/images/starIcon.png" alt="Star Icon" /> sss
                  {i18n.t("Feedback.Reflect")}
                </Typography>
                <Close
                  onClick={this.handleFeedbackCancel}
                  style={{ color: "#000000" }}
                />
              </Toolbar>
            </AppBar>
          </div>

          <div className={classes.dialogContent}>
            <SwipeableViews
              index={parseInt(feedbackStep, 10) - 1}
              onChangeIndex={this.handleChangeIndex}
              containerStyle={{
                WebkitOverflowScrolling: "touch",
                height: `calc(${windowHeight - headerHeightFeedback}px)`,
              }}
            >
              <FeedbackThumbs
                handleSendFeedbackButton={this.handleSendFeedbackButton}
                id={1}
                question={1}
                apStep={apStep}
                testGroup={AB_GROUP_B}
                userId={userId}
                key={1}
                next={this.handleFeedbackFinished}
                stepBack={this.stepBack}
              />
            </SwipeableViews>
          </div>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  windowHeight: state.windowHeight,
  reflectFeedback: state.reflectFeedback,
  softLogin: state.softLogin,
});

export default withRouter(
  connect(mapStateToProps, {
    submitReflectFeedback,
    updateReflectFeedback,
    clearReflectFeedback,
    fetchActionPlanDetail,
  })(withStyles(styles, Colors)(FeedbackB))
);
