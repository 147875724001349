import React from "react";
import { withRouter, Route } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import { parentTheme } from "../themes/parentTheme";
import { FCstore } from "../../store/FCstore";
import ReadingLogV5 from "./ReadingLogV5/ReadingLogV5";
import NetworkIssuePopup from "./Feedback/NetworkIssuePopup";
import StarsIncreaseScreen from "./StarsIncrease/StarsIncreaseScreen";
import BottomNavV3 from "./BottomNavV3";
import GoalV3 from "./Goal/GoalV3";
import ActionPlan from "./ActionPlan/ActionPlan";
import ActionPlanFactory from "./ActionPlan/ActionPlanFactory";

const pTheme = createTheme(parentTheme);

const FamilyCompanion = (props) => {
  const { match } = props;

  return (
    <Provider store={FCstore}>
      <div
        id="FamilyCompanionContainer"
        style={{
          maxWidth: 768,
          margin: "0 auto",
          background: "#FFF",
          height: "100%",
        }}
      >
        <MuiThemeProvider theme={pTheme}>
          <Route
            path={`${match.url}/goal/:actionPlanId/:stepId/:userId/:authToken/unsubscribe`}
            component={GoalV3}
          />
          <Route
            path={`${match.url}/action-plans/:actionPlanId/:stepId/:userId/:authToken`}
            component={ActionPlanFactory}
          />
          <Route
            path={`${match.url}/reading-log/:actionPlanId/:stepId/:userId/:authToken`}
            component={ReadingLogV5}
          />
          <Route
            exact
            path={`${match.url}/goal/:actionPlanId/:stepId/:userId/:authToken`}
            component={GoalV3}
          />
          <StarsIncreaseScreen />
          <NetworkIssuePopup />
          <BottomNavV3 />
        </MuiThemeProvider>
      </div>
    </Provider>
  );
};

export default withRouter(FamilyCompanion);
