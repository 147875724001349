import {
  OPEN_CHANGE_REMINDER,
  FETCH_CHANGE_REMINDER_VALUES,
  SKIP_CHANGE_REMINDER,
} from "./actionTypes";
import vaultApi from "../../../apis/vaultApi";

export const openChangeReminder = (open) => async (dispatch) => {
  dispatch({ type: OPEN_CHANGE_REMINDER, payload: open });
};

export const skipChangeReminder = (open) => async (dispatch) => {
  dispatch({ type: SKIP_CHANGE_REMINDER, payload: open });
};

export const saveChangeReminder =
  (userId, reminderTime, reminderDay, reminderFrequency, authToken) =>
  async (dispatch) => {
    try {
      const response = await vaultApi.patch(`/students/${userId}`, {
        reminder_time: reminderTime,
        reminder_day: reminderDay,
        reminder_frequency: reminderFrequency,
        auth_token: authToken,
      });
      dispatch({ type: FETCH_CHANGE_REMINDER_VALUES, payload: response.data });
      return "saved";
    } catch (err) {
      return err;
      // window.location.href = '/error';
    }
  };
