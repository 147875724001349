import React from "react";
import ReactDOM from "react-dom";
// import { store } from "./store";
// import { createStore, applyMiddleware, compose } from "redux";
// import thunk from "redux-thunk";

import "./index.css";

import App from "./components/App";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register();
