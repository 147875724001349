import React, { Component } from "react";
import { Switch, Router, Route, Redirect } from "react-router-dom";

import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import Rollbar from "rollbar";
import HttpsRedirect from "react-https-redirect";
import TagManager from "react-gtm-module";
import NotFound from "./NotFound";
import config from "../config";
import LandingPage from "./LandingPage/LandingPage";
import FamilyCompanion from "./FamilyCompanion/FamilyCompanion";
import LoginError from "./LoginError";
import ThankYouPage from "./LandingPage/ThankYouPage";

ReactGA.initialize(config.ga.id, { debug: config.ga.debug, titleCase: false });

TagManager.initialize({ gtmId: config.gtm });

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

class App extends Component {
  constructor(props) {
    super(props);

    const enableRollbar = process.env.REACT_APP_ENV === "production";

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      rollbar: new Rollbar({
        environment: process.env.REACT_APP_ENV,
        accessToken: "6bc6e00f37f743a399b000f0ebb50689",
        captureUncaught: enableRollbar,
        captureUnhandledRejections: enableRollbar,
      }),
    };
  }

  getSessionId = () =>
    `${new Date().getTime()}.${Math.random().toString(36).substring(5)}`;

  render() {
    return (
      <HttpsRedirect disabled={process.env.REACT_APP_ENV !== "production"}>
        <Router history={history}>
          <Switch>
            <Redirect
              from="/action-plans/:actionPlanId/:stepId/:userId/:authToken"
              to="/family-companion/action-plans/:actionPlanId/:stepId/:userId/:authToken"
            />
            <Route path="/family-companion" component={FamilyCompanion} />
            <Route path="/login_error" exact component={LoginError} />
            <Route
              path="/thank_you/:lang/:code?"
              exact
              component={ThankYouPage}
            />
            <Route
              path="/educator"
              exact
              strict
              render={() => window.location.replace("/educator/")}
            />
            <Route
              path="/teacher-companion"
              exact
              strict
              render={() => window.location.replace("/educator/")}
            />
            <Route path="/:code?" exact component={LandingPage} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </HttpsRedirect>
    );
  }
}

export default App;
