import React from "react";
import { withStyles, Typography } from "@material-ui/core";

const styles = (theme) => ({
  stepTip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: "#FFFBED",
  },
  tipIcon: {
    flex: "0 0 1",
    padding: theme.spacing(1),
  },
  tipText: {
    flex: "1 1 1",
  },
  tipTypography: {
    lineHeight: "1.3",
  },
});

const Tip = props => {
  const { tip, classes } = props;
  return (
    <div className={classes.stepTip}>
      <div className={classes.tipIcon}>
        <img src="/images/bulbIcon.png" alt="bulb icon" width="20" />
      </div>
      <div className={classes.tipText}>
        <Typography className={classes.tipTypography} variant="body2">
          {tip}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(Tip);
