import React from "react";
import { withStyles, CircularProgress } from "@material-ui/core";

const styles = () => ({
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
  },
});

const Loading = (props) => {
  const { classes, size = 60, style = {} } = props;

  return (
    <div className={classes.progress} style={style}>
      <CircularProgress size={size} />
    </div>
  );
};

export default withStyles(styles)(Loading);
