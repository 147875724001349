import React from "react";
import { withStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { isNil } from "ramda";
import i18n from "../../../i18n/i18next";

const positiveStyle = {
  fontSize: 25,
  color: "#FFF",
};

const negativeStyle = {
  fontSize: 25,
  color: "#F00",
};

const getIconForStatus = (status, classes, disabled) => {
  if (disabled) {
    return <div className={classes.disabledStatus} />;
  }
  if (status === "positive") {
    return <CheckCircleIcon style={positiveStyle} />;
  }
  if (status === "negative") {
    return <CancelIcon style={negativeStyle} />;
  }
  return <div className={classes.emptyStatus} />;
};

const ReadingLogButtonV5 = (props) => {
  const {
    classes,
    day,
    status,
    value,
    click,
    ariaDay,
    isCurrent = false,
    isActive = false,
    disabled = false,
  } = props;

  const buttonStyles = {
    backgroundColor: isCurrent ? "#6BD9B2" : "#A4F1D6",
    border: isActive || isCurrent ? "2px solid #6BD9B2" : "2px solid #A4F1D6",
  };

  return (
    <button
      type="button"
      onClick={() => click(day)}
      className={classes.container}
      style={buttonStyles}
      disabled={disabled}
      aria-label={ariaDay}
    >
      <span className={classes.day}>{i18n.t(`recordProgress.${day}`)}</span>
      {!isNil(value) && <span className={classes.value}>{value}</span>}
      <span>{getIconForStatus(status, classes, disabled)}</span>
    </button>
  );
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 44,
    appearance: "none",
    backgroundColor: "#A4F1D6",
    boxShadow: "0 4px 0 #6BD9B2",
    borderRadius: 10,
    margin: "0 4px",
  },
  day: {
    background: "#D9FFF6",
    color: "#333",
    fontSize: 10,
    fontWeight: 500,
    borderRadius: 10,
    marginBottom: theme.spacing(1),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: "block",
    width: "20px",
  },
  value: {
    fontSize: 10,
    fontWeight: 300,
    textAlign: "center",
    paddingBottom: theme.spacing(1),
  },
  emptyStatus: {
    height: 21,
    width: 21,
    borderRadius: 11,
    background: "#D9FFF6",
    marginBottom: 6,
    marginTop: 2,
  },
  disabledStatus: {
    height: 21,
    width: 21,
    borderRadius: 11,
    background: "#EEE",
    marginBottom: 6,
    marginTop: 2,
  },
});

export default withStyles(styles)(ReadingLogButtonV5);
