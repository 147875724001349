import React, { Component } from "react";
import { withStyles, Dialog, Paper, Fade, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

import { isEmpty, isNil } from "ramda";
import { withRouter } from "react-router-dom";
import i18n from "../../../i18n/i18next";
import NextButton from "../../Icons/NextButton";
import InnerHeight from "../../helpers/InnerHeight";
import { sendEvent, getSessionName } from "../../helpers/GaProvider";

const CustomPaper = withStyles(() => ({
  root: {
    background: "rgba(0,0,0,0.3)",
    maxWidth: 768,
  },
}))(Paper);

class TempTiger extends Component {
  close = () => {
    const { link, history, softLogin, match } = this.props;
    const { actionPlanId, userId, stepId } = match.params;
    const sessionName = getSessionName(softLogin);

    sendEvent(
      "Weekly Reflection: Got It!",
      `{apID: ${actionPlanId}, apStep: ${stepId}, userID: ${userId}, sessionName: ${sessionName}}`
    );

    if (!isNil(link) && !isEmpty(link)) {
      history.push(link);
    }
  };

  showHelpWidget = () => {
    const { softLogin, freshdesk, createWidget } = this.props;

    createWidget(
      { email: softLogin.parentInfo.email, name: softLogin.parentInfo.name },
      freshdesk.created
    );
  };

  render() {
    const { classes, open } = this.props;

    return (
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Fade}
        PaperComponent={CustomPaper}
      >
        <div className={classes.container}>
          <InnerHeight />
          <div className={classes.header}>
            <IconButton
              aria-label="Back"
              onClick={this.close}
              style={{ width: 50, height: 50 }}
            >
              <ChevronLeft fontSize="large" />
            </IconButton>
            <span className={classes.title}>
              {i18n.t("ProgramSummary.Activity")}
            </span>
          </div>

          <div className={classes.feedbackFinish}>
            <div className={classes.successScreen}>
              <img
                alt="success"
                src="/images/tiger.svg"
                style={{ width: "100%" }}
              />
              <div className={classes.message}>
                <h2 style={{ margin: 0, fontSize: 20, textAlign: "center" }}>
                  {i18n.t("Activity.You just reflected for this week")}
                </h2>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <NextButton onClick={this.close} text={i18n.t("Feedback.Next")} />
          </div>
        </div>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    backgroundColor: "#FFF",
    flex: 1,
  },
  header: {
    paddingLeft: 5,
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    display: "flex",
    alignItems: "center",
    background: "#fff",
    borderBottom: "solid 4px #F5F5F5",
  },
  title: {
    marginLeft: 5,
    textAlign: "center",
    width: "100%",
    color: "#242424",
    fontWeight: 700,
  },
  successScreen: {
    backgroundColor: "#F7F5FF",
    borderRadius: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
    paddingTop: theme.spacing(2),
    justifyContent: "flex-start",
  },
  feedbackFinish: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flex: 1,
  },
  progress: {
    display: "flex",
    flex: 2,
    padding: theme.spacing(2),
    alignItems: "center",
  },
  message: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default withRouter(withStyles(styles)(TempTiger));
