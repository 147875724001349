import { all, ascend, descend, isEmpty, isNil, prop, sortWith } from "ramda";

const apInfo = (currentApa, student) => ({
  title: (currentApa.actionPlan || {}).title,
  image: (currentApa.actionPlan || {}).image,
  id: currentApa.actionPlanId,
  apaId: currentApa.id,
  stepId: currentApa.currentStep,
  focusArea: currentApa.focusArea,
  program: currentApa.program,
  apaUrl: `/family-companion/action-plans/${currentApa.actionPlanId}/1/${student.id}/${student.securityToken}`,
  progress: currentApa.strategyProgress,
  weekNumber: currentApa.weekNumber,
  active: currentApa.active,
});

const getApas = (s) =>
  isNil(s.actionPlanAssignments)
    ? s.slaPrograms[0].actionPlanAssignments
    : s.actionPlanAssignments;

const programInfo = (program, student) => ({
  id: program.id,
  name: program.name,
  programName: program.programName,
  startDate: program.startDate,
  endDate: program.endDate,
  active: program.active,
  archivedApas: program.actionPlanAssignments
    .filter((apa) => apa.archived)
    .map((apa) => apInfo(apa, student)),
  currentApa: program.actionPlanAssignments
    .filter((apa) => apa.active)
    .map((apa) => apInfo(apa, student))[0],
  inactiveApas: program.actionPlanAssignments
    .filter((apa) => !apa.active && !apa.archived)
    .map((apa) => apInfo(apa, student)),
  allowAddingPoints: program.allowAddingPoints,
  duration: program.weeksDuration,
  thresholds: program.thresholds,
  goalSetting: program.goalSetting,
  features: program.features,
});

const getCurrentStep = (currentApa) =>
  isNil(currentApa.actionPlanId)
    ? 0
    : parseInt(currentApa.currentStep, 10) === 0
    ? 1
    : currentApa.currentStep;

const generateGoalLink = (currentApa, student) => {
  const currentStep = getCurrentStep(currentApa);
  return `/family-companion/goal/${
    currentApa.actionPlanId || 0
  }/${currentStep}/${student.id}/${student.securityToken}`;
};

const generatePracticeLink = (currentApa, student) => {
  const currentStep = getCurrentStep(currentApa);
  return `/family-companion/action-plans/${
    currentApa.actionPlanId || 0
  }/${currentStep}/${student.id}/${student.securityToken}`;
};

const siblingInfo = (student) => {
  const apas = getApas(student);

  const activeApa = apas.filter((apa) => apa.active === true);
  const lastApa = !isEmpty(student.actionPlanAssignments)
    ? apas[apas.length - 1]
    : {};
  const currentApa =
    !isEmpty(activeApa) && !isNil(activeApa) ? activeApa[0] : lastApa;

  const goalLink = generateGoalLink(currentApa, student);
  const practiceLink = generatePracticeLink(currentApa, student);

  const targetLink =
    isNil(currentApa) || isNil(prop("actionPlanId", currentApa))
      ? practiceLink
      : goalLink;

  return {
    id: student.id,
    firstName: student.firstName,
    goalLink: targetLink,
  };
};

const studentInfo = (student) => {
  let stateObject = {};

  const programs = studentPrograms(student);
  const apas = getApas(student);
  const activeApa = apas.filter((apa) => apa.active === true);
  const firstApa = !isEmpty(apas) ? apas[0] : {};

  const currentApa =
    !isEmpty(activeApa) && !isNil(activeApa) ? activeApa[0] : firstApa;

  const archivedApas = apas.filter((apa) => apa.archived === true);

  const currentStep = getCurrentStep(currentApa);

  const goalLink = generateGoalLink(currentApa, student);
  const practiceLink = generatePracticeLink(currentApa, student);

  const logLink = `/family-companion/reading-log/${
    currentApa.actionPlanId || 0
  }/${currentStep}/${student.id}/${student.securityToken}`;
  const homeLink = `/family-companion/home/${
    currentApa.actionPlanId || 0
  }/${currentStep}/${student.id}/${student.securityToken}`;

  stateObject = {
    id: student.id,
    firstName: student.firstName,
    authToken: student.securityToken,
    practiceLink,
    logLink,
    goalLink,
    homeLink,
    programs,
    reminderFrequency: student.reminderFrequency,
    reminderDay: student.reminderDay,
    reminderTime: student.reminderTime,
    reminderType: student.reminderType,
    emailProvided: student.emailProvided,
    phoneProvided: student.phoneProvided,
    language: student.language,
    showRecordProgress: student.showRecordProgress,
    appVersion: student.appVersion,
    programActive: student.programActive,
    clickedPreview: student.programActive,
    targets: student.targets,
    archivedApas:
      !isEmpty(archivedApas) && !isNil(archivedApas)
        ? archivedApas
            .map((apa) => apInfo(apa, student))
            .sort((a, b) => a.weekNumber - b.weekNumber)
        : [],
    currentApa:
      !isEmpty(currentApa) && !isNil(currentApa)
        ? apInfo(currentApa, student)
        : [],
  };

  return stateObject;
};

const studentPrograms = (student) => {
  const propActive = prop("active");
  const propStartDate = prop("startDate");

  const activeStartDateSort = sortWith([
    descend(propActive),
    ascend(propStartDate),
  ]);

  return activeStartDateSort(
    student.slaPrograms.map((program) => programInfo(program, student))
  );
};

const studentCurrentApa = (programs) => {
  const activePrograms = programs.filter((program) => program.active);
  const activeProgram =
    activePrograms.length === 1
      ? activePrograms[0]
      : programs[programs.length - 1];

  return !isNil(activeProgram.currentApa)
    ? activeProgram.currentApa
    : activeProgram.archivedApas[activeProgram.archivedApas.length - 1] ||
        activeProgram.inactiveApas[0];
};

export const studentGoalLink = (student) => {
  const programs = studentPrograms(student);
  const currentApa = isEmpty(programs) ? null : studentCurrentApa(programs);

  if (isNil(currentApa)) {
    return null;
  }

  return `/family-companion/goal/${currentApa.id}/1/${student.id}/${student.securityToken}`;
};

export const parseChildren = (sf, childId) => {
  let activeChild = null;
  let otherChildren = [];

  const students = sf.students.filter(
    (s) =>
      !s.archived &&
      (s.appVersion === "v3"
        ? !isEmpty(s.actionPlanAssignments)
        : !isEmpty(s.slaPrograms))
  );

  if (students.length > 1) {
    if (childId !== null) {
      activeChild = students.filter(
        (s) => parseInt(s.id, 10) === parseInt(childId, 10)
      );
      activeChild = studentInfo(activeChild[0]);

      otherChildren = students
        .filter((s) => parseInt(s.id, 10) !== parseInt(childId, 10))
        .map((s) => siblingInfo(s));
    } else {
      const os = students;
      activeChild = studentInfo(os[0]);
      os.shift();

      otherChildren = os.map((s) => siblingInfo(s));
    }
  } else {
    activeChild = studentInfo(
      students.length > 0 ? students[0] : sf.students[0]
    );
  }

  return {
    parentInfo: {
      email: sf.email,
      id: sf.id,
      name: sf.name,
      phoneNumber: sf.phoneNumber,
      salesforceId: sf.salesforceId,
    },
    activeChild,
    otherChildren,
    lang: activeChild.language,
  };
};
