import React, { Component } from "react";
import { connect } from "react-redux";
import { setHeight, setWidth } from "../../actions";
import { FCstore } from "../../store/FCstore";

class InnerHeight extends Component {
  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const { setHeight, setWidth } = this.props;
    const { windowHeight, windowWidth } = FCstore.getState();
    if (windowHeight !== window.innerHeight) {
      setHeight(window.innerHeight);
    }
    if (windowWidth !== window.innerWidth) {
      setWidth(window.innerWidth);
    }
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = (state) => ({ windowHeight: state.windowHeight });

export default connect(mapStateToProps, { setHeight, setWidth })(InnerHeight);
