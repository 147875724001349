import {
  OPEN_CHANGE_REMINDER_TYPE,
  FETCH_REMINDER_TYPE_VALUES,
} from "./actionTypes";

const initialPayload = {
  open: false,
  close: false,
  reminderType: null,
};

const changeReminderTypeReducer = (payload = initialPayload, action) => {
  if (action.type === OPEN_CHANGE_REMINDER_TYPE) {
    return { ...payload, open: action.payload };
  }
  if (action.type === FETCH_REMINDER_TYPE_VALUES) {
    return { ...payload, reminderType: action.payload.reminderType };
  }
  return payload;
};

export default changeReminderTypeReducer;
