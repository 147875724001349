import { SET_NETWORK_ERROR, UNSET_NETWORK_ERROR } from "../actionTypes";

const initial = {
  open: false,
  funcs: {} // Map: url => function
};

const NetworkErrorReducer = (payload = initial, action) => {
  if (action.type === SET_NETWORK_ERROR) {
    const functions = payload.funcs;
    functions[action.payload.url] = action.payload.func;
    return {
      open: true,
      funcs: functions
    };
  }
  if (action.type === UNSET_NETWORK_ERROR) {
    return {
      open: false,
      funcs: {}
    };
  }
  return payload;
};

export default NetworkErrorReducer;
