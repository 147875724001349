import React from "react";
import { Typography, withStyles } from "@material-ui/core";

import i18n from "../../../i18n/i18next";

const styles = (theme) => ({
  successScreen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  "@keyframes successAnim": {
    from: {
      transform: "scale(0) rotate(-360deg)",
      opacity: 0,
    },
    to: {
      transform: "scale(1) rotate(0deg)",
      opacity: 1,
    },
  },
  successAnim: {
    animation: "successAnim 2s cubic-bezier(0.680, -0.550, 0.265, 1.550) .5s both",
  },
  greatNewsImg: {
    width: 200,
    marginTop: "20%",
    marginBottom: theme.spacing(2),
  },
  greatNews: {
    fontWeight: 700,
    lineHeight: "1.3",
    letterSpacing: "0.5px",
    color: "#000",
    fontSize: 20,
    position: "relative",
    zIndex: 999999,
  },
  successAnimConfetti: {
    animation:
      "successAnimConfetti 1s cubic-bezier(0.680, -0.550, 0.265, 1.550) both",
    position: "absolute",
    left: "2vw",
    top: "2vh",
    width: "80vw",
  },
  "@keyframes successAnimConfetti": {
    from: {
      transform: "scale(0)",
      opacity: 0,
    },
    to: {
      transform: "scale(1)",
      opacity: 1,
    },
  },
});

const SuccessScreen = props => {
  const { classes, name, percents, minutes } = props;

  return (
    <div className={classes.successScreen}>
      <img
        src="/images/confetti.png"
        alt="confetti"
        className={classes.successAnimConfetti}
      />
      <img
        src="/images/FeedbackSmilingFace.png"
        alt="feedback smile"
        className={`${classes.greatNewsImg} ${classes.successAnim}`}
      />
      <Typography
        className={classes.greatNews}
        variant="body1"
        align="center"
      >
        {parseInt(percents, 10) === 0
          ? i18n.t("Feedback.success1", {
            name,
            minutes,
          })
          : percents == null
            ? i18n.t("Feedback.success2", {
              name,
              minutes,
            })
            : i18n.t("Feedback.success3", {
              name,
              minutes,
              percents,
            })}
        <br />
        {i18n.t("Feedback.Keep it up!")}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(SuccessScreen);
