export const createFreshdeskWidget = () => {
  const secondScript = document.createElement("script");

  secondScript.src = "https://widget.freshworks.com/widgets/62000001146.js";
  secondScript.async = true;
  secondScript.defer = true;

  document.body.appendChild(secondScript);

  return () => {
    document.body.removeChild(secondScript);
  };
};

export const addHelpButton = () => {
  const firstScript = document.createElement("script");
  firstScript.innerHTML = "window.fwSettings = {'widget_id':62000001146};!(function () { if (typeof window.FreshworksWidget !== 'function') { var n = function () { n.q.push(arguments); }; n.q = [], window.FreshworksWidget = n; } }())";

  document.body.appendChild(firstScript);

  return () => {
    document.body.removeChild(firstScript);
  };
};

export const hideNameAndEmail = () => {
  const script = document.createElement("script");
  script.innerHTML = "FreshworksWidget('hide', 'ticketForm', ['name', 'email'])";

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
};

export const setNameAndEmail = (user) => {
  if (user === undefined
    || user.name === undefined
    || user.email === undefined) { return null; }
  const script = document.createElement("script");
  script.innerHTML = `FreshworksWidget('identify', 'ticketForm', {name: '${user.name}', email: '${user.email}'})`;

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
};

export const toggleShowFreshdeskWidget = (visible) => {
  const firstScript = document.createElement("script");
  firstScript.innerHTML = `FreshworksWidget(${visible ? "'show'" : "'hide', 'launcher'"});`;

  document.body.appendChild(firstScript);

  return () => {
    document.body.removeChild(firstScript);
  };
};

export const openFreshdeskWidget = () => {
  const firstScript = document.createElement("script");
  firstScript.innerHTML = "FreshworksWidget('open');";

  document.body.appendChild(firstScript);

  return () => {
    document.body.removeChild(firstScript);
  };
};

export const setUpAndOpenFreshdeskWidget = (user, created) => {
  if (!created) {
    addHelpButton();
    createFreshdeskWidget();
    setNameAndEmail(user);
    hideNameAndEmail();
    toggleShowFreshdeskWidget(false);
  }
  openFreshdeskWidget();
};

export const landingPageWidget = () => {
  addHelpButton();
  createFreshdeskWidget();
};

export const destroyLandingPageWidget = () => {
  const firstScript = document.createElement("script");
  firstScript.innerHTML = "FreshworksWidget('destroy');";

  document.body.appendChild(firstScript);

  return () => {
    document.body.removeChild(firstScript);
  };
};
