import React, { Component } from "react";
import {
  withStyles,
  Typography,
  FormControl,
  NativeSelect,
  Dialog,
  Paper,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Close } from "@material-ui/icons";
import vault from "../../../apis/vaultApi";

import {
  saveSoftLogin,
  fetchActionPlanDetail,
  getStrategies,
} from "../../../actions";

import { changeLanguageStyles } from "./styles";
import { CustomInput } from "../Feedback/customInput";

import { TransitionUp } from "../../helpers/Transition";
import i18n from "../../../i18n/i18next";
import NextButton from "../../Icons/NextButton";

const styles = (theme) => changeLanguageStyles(theme);

const CustomPaper = withStyles(() => ({
  root: {
    background: "rgba(0,0,0,0.3)",
    maxWidth: 768,
    zIndex: "1510 !important",
  },
}))(Paper);

class ChangeLanguage extends Component {
  constructor(props) {
    super(props);
    const { language } = this.props;
    this.state = {
      open: false,
      language,
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.close);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.close);
  }

  close = (e) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (e.key === "Escape" && this.state.open === true) {
      this.handleClickClose();
    }
  };

  handleChangeLanguage = (e) => {
    this.setState({ language: e.target.value });
  };

  setLanguage = () => {
    const { softLogin, saveSoftLogin, fetchActionPlanDetail, getStrategies } =
      this.props;
    const { id, authToken, currentApa } = softLogin.activeChild;
    const { language } = this.state;

    this.setState({
      langButtonText: i18n.t("Feedback.Saving"),
      langBtnDisabled: true,
    });

    setTimeout(() => {
      vault
        .post("/set_student_language", {
          id,
          auth_token: authToken,
          language,
        })
        .then(() => {
          this.setState(
            {
              langButtonText: i18n.t("Feedback.Saved"),
              langBtnDisabled: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ language });
                saveSoftLogin(id);
                fetchActionPlanDetail(currentApa.id, id, authToken);
                getStrategies(id, authToken);
                this.handleClickClose();
              }, 1000);
            }
          );
        });
    }, 300);
  };

  handleClickClose = () => {
    this.setState({ open: false });
  };

  open() {
    // eslint-disable-next-line react/destructuring-assignment
    const { language } = this.props.softLogin.activeChild;

    this.setState({
      open: true,
      language,
      langButtonText: i18n.t("Feedback.Select"),
    });
  }

  render() {
    const { langBtnDisabled, langButtonText, open, language } = this.state;
    const { classes } = this.props;

    return (
      <Dialog
        fullScreen
        open={open}
        disableEscapeKeyDown
        TransitionComponent={TransitionUp}
        PaperComponent={CustomPaper}
        classes={{
          root: classes.dialog,
        }}
      >
        <DialogTitle
          className={classes.dialogTitle}
          style={{ height: window.innerHeight * 0.2 }}
          onClick={() => this.handleClickClose()}
        >
          {" "}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography className={classes.title} style={{ flex: 1 }}>
              {i18n.t("Feedback.Language")}
            </Typography>
            <IconButton
              onClick={this.handleClickClose}
              onKeyPress={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  this.handleClickClose();
                }
              }}
              style={{ backgroundColor: "#EFEFEF" }}
            >
              <Close />
            </IconButton>
          </div>

          <div className={classes.image}>
            <img src="/images/setReminderTimerImg.jpg" alt="Change" />
          </div>

          <div className={classes.panel}>
            <Typography
              paragraph
              variant="body1"
              className={classes.when}
              align="left"
            >
              {i18n.t("Feedback.Change language")}
            </Typography>

            <FormControl>
              <NativeSelect
                style={{ width: "100%" }}
                value={language}
                onChange={this.handleChangeLanguage}
                name="language"
                input={<CustomInput name="language" id="language" />}
              >
                <option value="en">{i18n.t("Feedback.English")}</option>
                <option value="es">{i18n.t("Feedback.Spanish")}</option>
              </NativeSelect>
            </FormControl>

            <div className={classes.submit}>
              <NextButton
                onClick={this.setLanguage}
                disabled={langBtnDisabled}
                text={langButtonText}
                backgroundColor="#474747"
                size={44}
                buttonStyle={{
                  width: "100%",
                  margin: 0,
                  fontSize: 16,
                  color: "#FFF",
                  boxShadow: "0px 5px 0px #242424",
                }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({ softLogin: state.softLogin });

export default withRouter(
  connect(mapStateToProps, {
    saveSoftLogin,
    fetchActionPlanDetail,
    getStrategies,
  })(withStyles(styles)(ChangeLanguage))
);
