import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import { isNil } from "ramda";
// import { parentTheme } from "../themes/parentTheme";
import i18n from "../../i18n/i18next";
import {
  destroyLandingPageWidget,
  landingPageWidget,
} from "../FamilyCompanion/Freshdesk/widget";
import MaintenanceView from "./MaintenanceView";

// const pTheme = createTheme(parentTheme);

class LandingPage extends Component {
  state = {
    // lang: i18n.language
  };

  componentDidMount() {
    ReactGA.event({
      category: "LandingPage",
      action: "Opened",
    });

    this.detectBrowserLanguageOrDefault();
    landingPageWidget();
  }

  componentWillUnmount() {
    destroyLandingPageWidget();
  }

  detectBrowserLanguageOrDefault = () => {
    let browserLang = navigator.language.split("-")[0];
    if (isNil(browserLang) || !i18n.languages.includes(browserLang)) {
      browserLang = "en";
    }
    i18n.changeLanguage(browserLang);
    // this.setState({ lang: browserLang });
    return browserLang;
  };

  render() {
    return <MaintenanceView {...this.props} />;
  }
}

const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100vw",
    minHeight: "100vh",
    paddingBottom: 70,
    background: "#ffffff",
  },
});

export default withRouter(withStyles(styles)(LandingPage));
