import React from "react";
import { FormControl, NativeSelect } from "@material-ui/core";
import i18n from "../../../i18n/i18next";
import { isNil } from "ramda";

const MinutesSelect = (props) => {
  const { classes, onChange, selectedValue, label, disabled = false } = props;
  const val = isNil(selectedValue) || selectedValue > 60 ? "" : selectedValue;
  const min = i18n.t("recordProgress.minutes");

  return (
    <FormControl className={classes.formControl}>
      <NativeSelect
        variant="outlined"
        className={classes.select}
        aria-label={label}
        value={val}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
      >
        <option disabled value="">
          {i18n.t("recordProgress.Select")}
        </option>
        <option value="0">0 {min}</option>
        <option value="5">5 {min}</option>
        <option value="10">10 {min}</option>
        <option value="15">15 {min}</option>
        <option value="20">20 {min}</option>
        <option value="25">25 {min}</option>
        <option value="30">30 {min}</option>
        <option value="35">35 {min}</option>
        <option value="40">40 {min}</option>
        <option value="45">45 {min}</option>
        <option value="50">50 {min}</option>
        <option value="55">55 {min}</option>
        <option value="60">60 {min}</option>
      </NativeSelect>
    </FormControl>
  );
};

export default MinutesSelect;
