import React, { Component } from "react";

import { Typography, withStyles } from "@material-ui/core";
import i18n from "../../../i18n/i18next";

const styles = (theme) => ({
  worriedScreen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },

  "@keyframes worriedAnim": {
    from: {
      transform: "scale(0)",
      opacity: 0,
    },
    to: {
      transform: "scale(1)",
      opacity: 1,
    },
  },
  worriedAnim: {
    animation: "worriedAnim 2s linear  both",
  },
  greatNewsImg: {
    width: 200,
    marginTop: "20%",
    marginBottom: theme.spacing(2),
  },
  greatNews: {
    fontWeight: 700,
    lineHeight: "1.3",
    letterSpacing: "0.5px",
    color: "#000",
    fontSize: 20,
    position: "relative",
    zIndex: 999999,
  },
});

class WorriedScreen extends Component {
  state = {
    version: 1,
  };

  componentDidMount() {
    this.setState({ version: Math.floor(Math.random() * (3 - 1 + 1) + 1) });
  }

  render() {
    const { classes, name, percents, minutes } = this.props;
    const { version } = this.state;
    return (
      <div className={classes.worriedScreen}>
        <img
          src="/images/FeedbackWorriedFace.png"
          alt="feedback smile"
          className={`${classes.greatNewsImg} ${classes.worriedAnim}`}
        />
        {version === 1 && (
          <Typography
            className={classes.greatNews}
            variant="body1"
            align="center"
          >
            {i18n.t("Feedback.worried1", {
              name,
              minutes,
              percents,
            })}

            <br />
            {i18n.t(
              "Feedback.Want to learn a new strategy to get some reading mojo back?"
            )}
          </Typography>
        )}
        {version === 2 && (
          <Typography
            className={classes.greatNews}
            variant="body1"
            align="center"
          >
            {i18n.t("Feedback.worried1", {
              name,
              minutes,
              percents,
            })}
            <br />
            {i18n.t("Talk to teacher", { name })}
          </Typography>
        )}
        {version === 3 && (
          <Typography
            className={classes.greatNews}
            variant="body1"
            align="center"
          >
            {i18n.t("Feedback.worried1", {
              name,
              minutes,
              percents,
            })}
            <br />
            {i18n.t("Reading takes some practice but you can do it!")}
          </Typography>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(WorriedScreen);
