import React, { Component } from "react";
import { isEmpty } from "ramda";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  withStyles,
} from "@material-ui/core";
import i18n from "../../i18n/i18next";
import PracticeIcon from "../Icons/PracticeIcon";
import GoalIcon from "../Icons/GoalIcon";
import ReadingIcon from "../Icons/ReadingIcon";

const styles = () => ({
  root: {
    position: "fixed",
    bottom: 0,
    zIndex: 1000,
    width: "100%",
    maxWidth: 768,
    borderTop: "2px solid #f6f6f6",
  },
  item: {
    letterSpacing: "-0.5px",
    fontWeight: 500,
    textAlign: "center",
    paddingLeft: 0,
    paddingRight: 0,
  },
  selected: {
    "&$selected": {
      color: "#2F6EBB",
      fontSize: 12,
    },
  },
});

class BottomNavV3 extends Component {
  state = {
    currentLink: "action-plans",
  };

  componentDidMount() {
    const { history } = this.props;

    history.listen((location) => {
      this.handleCurrentLocation(location.pathname);
    });

    this.handleCurrentLocation(window.location.pathname);
  }

  handleCurrentLocation = (pathname) => {
    if (pathname.includes("action-plans")) {
      this.setState({ currentLink: "action-plans" });
    } else if (pathname.includes("goal")) {
      this.setState({ currentLink: "goal" });
    } else if (pathname.includes("reading")) {
      this.setState({ currentLink: "reading" });
    } else {
      this.setState({ currentLink: "action-plans" });
    }
  };

  onChange = ({ value }) => {
    this.setState({ currentLink: value });
  };

  render() {
    const { currentLink } = this.state;
    const { softLogin, classes } = this.props;

    const goalLink = isEmpty(softLogin) ? "" : softLogin.activeChild.goalLink;
    const practiceLink = isEmpty(softLogin)
      ? ""
      : softLogin.activeChild.practiceLink;
    const logLink = isEmpty(softLogin) ? "" : softLogin.activeChild.logLink;
    const goalDisabled = goalLink.includes("/0/0/");

    return (
      <BottomNavigation
        className={classes.root}
        value={currentLink}
        showLabels
        onChange={this.onChange}
      >
        <span id="desc" aria-hidden style={{ display: "none" }}>
          Activating the following buttons will update the content above.
        </span>
        <BottomNavigationAction
          classes={{
            root: classes.item,
            selected: classes.selected,
          }}
          aria-current={currentLink === "action-plans" ? "page" : ""}
          title={i18n.t(
            "BottomNav.Activating the following buttons will update the content above"
          )}
          component={Link}
          to={practiceLink}
          role="button"
          centerRipple
          label={i18n.t("BottomNav.Practice")}
          value="action-plans"
          icon={
            <PracticeIcon
              fill={currentLink === "action-plans" ? "#2F6EBB" : "#BDBDBD"}
            />
          }
        />
        <BottomNavigationAction
          classes={{
            root: classes.item,
            selected: classes.selected,
          }}
          title={i18n.t(
            "BottomNav.Activating the following buttons will update the content above"
          )}
          aria-current={currentLink === "reading" ? "page" : ""}
          component={Link}
          to={logLink}
          role="button"
          centerRipple
          label={i18n.t("BottomNav.ReadingLog")}
          value="reading"
          icon={
            <ReadingIcon
              fill={currentLink === "reading" ? "#2F6EBB" : "#BDBDBD"}
            />
          }
        />
        <BottomNavigationAction
          classes={{
            root: classes.item,
            selected: classes.selected,
          }}
          title={i18n.t(
            "BottomNav.Activating the following buttons will update the content above"
          )}
          aria-current={currentLink === "goal" ? "page" : ""}
          component={Link}
          to={goalLink}
          role="button"
          centerRipple
          disabled={goalDisabled}
          style={goalDisabled ? { opacity: 0.4 } : {}}
          label={i18n.t("BottomNav.Goal")}
          value="goal"
          icon={
            <GoalIcon fill={currentLink === "goal" ? "#2F6EBB" : "#BDBDBD"} />
          }
        />
      </BottomNavigation>
    );
  }
}

const mapStateToProps = (state) => ({ softLogin: state.softLogin });

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(BottomNavV3))
);
