import React from "react";
import { Button } from "@material-ui/core";

const NextButton = (props) => {
  const {
    onClick = () => { },
    backgroundColor = "#49BFF1",
    size = 50,
    text = "Next",
    textColor = "#000",
    isDisabled,
    buttonStyle = {}
  } = props;

  const style = {
    minWidth: size,
    width: "100%",
    height: size,
    borderRadius: size / 2,
    backgroundColor,
    padding: 0,
    lineHeight: 1,
    margin: 8,
    fontSize: 22,
    fontWeight: 700,
    textTransform: "capitalize",
    boxShadow: "0px 5px 0px #1CAFEE",
    color: textColor,
    ...buttonStyle
  };
  const disabledStyle = {
    minWidth: size,
    width: "100%",
    height: size,
    borderRadius: size / 2,
    backgroundColor,
    padding: 0,
    lineHeight: 1,
    margin: 8,
    fontSize: 22,
    textTransform: "capitalize",
    fontWeight: 700,
    boxShadow: "0px 5px 0px #1CAFEE",
    opacity: 0.5,
    color: textColor,
    ...buttonStyle
  };

  return (
    <Button onClick={onClick} style={isDisabled ? disabledStyle : style} disabled={isDisabled}>
      {text}
    </Button>
  );
};

export default NextButton;
