import React, { Component } from "react";
import { withStyles, Button } from "@material-ui/core";
import i18n from "../../i18n/i18next";

const styles = () => ({
  thankYou: {
    height: "100vh",
  },
  body: {
    padding: "10px"
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  logoImg: {
    width: "50%",
    textAlign: "right",
  },
  bookmarkImg: {
    width: "100%"
  },
  title: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    background: "url(/images/landing-page/asset-7.png) 0 0 repeat-x #016FB6",
    backgroundSize: "27px 156px",
    padding: "10px 16px",
    height: 100,
    display: "flex",
    flexDirection: "column",
    "& h1": {
      color: "#ffffff",
      fontSize: 16,
      paddingTop: 6,
      paddingLeft: 8,
    },
  },
});

const CustomButton = withStyles(() => ({
  root: {
    margin: "30px auto 20px",
    width: "100%",
    maxWidth: "300px",
    display: "flex",
  },
  disabled: {
    background: "#E7F5FF !important",
    borderBottom: "4px solid #DAE8F2 !important",
  },
  text: {
    position: "relative",
    background: "rgb(81,199,60)",
    // eslint-disable-next-line no-dupe-keys
    background:
      "linear-gradient(90deg, rgba(81,199,60,1) 0%, rgba(150,201,62,1) 100%)",
    borderBottom: "4px solid #48A02D",
    borderRadius: 30,
    webkitAppearence: "none",
    fontSize: 18,
    fontWeight: 700,
    textTransform: "none",
    color: "#000000",
    padding: "10px 26px 10px 12px",
    fontFamily: "Gotham A",
    "&:focus": {
      background:
        "linear-gradient(270deg, rgba(81,199,60,1) 0%, rgba(150,201,62,1) 100%)",
      borderBottom: "4px solid #48A02D",
    },
  },
}))(Button);

class ThankYouPage extends Component {
  getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  continue = () => {
    const { history, match: { params: { code } } } = this.props;

    history.push(`/onboarding/${code}`);
  }

  render() {
    const { classes, match: { params: { code, lang } } } = this.props;
    const os = this.getMobileOperatingSystem();

    i18n.changeLanguage(lang);

    return (
      <div className={classes.thankYou}>
        <header className={classes.header}>
          <div className={classes.logo}>
            <a
              className={classes.logo}
              href="https://springboardcollaborative.org"
              title="Springboard Collaborative"
            >
              <img
                className={classes.logoImg}
                src="/images/landing-page/sblogo.png"
                alt="springboard connect logo"
              />
            </a>
          </div>
          <div className={classes.title}>
            <img
              className={classes.hiddenImage}
              src="/images/landing-page/asset-1.png"
              height="104"
              alt="Drawing of a lady reading a book with a child"
            />
            <h1>{i18n.t("ThankYouPage.Thank you for signing up")}</h1>
          </div>
        </header>
        <div className={classes.body}>
          <p>
            {i18n.t("ThankYouPage.Body1")}
          </p>
          <p>
            <span role="img" aria-label="Books emoji">📚</span>
            {i18n.t("ThankYouPage.Body2")}
          </p>
          <p>
            <span role="img" aria-label="Bulb emoji">💡</span>
            {i18n.t("ThankYouPage.Body3")}
          </p>

          <img
            className={classes.bookmarkImg}
            src={`/images/landing-page/bookmark-${os === "iOS" ? "ios" : "android"}.png`}
            alt="bookmark"
          />

          {code && (
            <CustomButton variant="text" onClick={this.continue}>
              {i18n.t("ThankYouPage.Next")}
            </CustomButton>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ThankYouPage);
