import { SET_HEIGHT } from "../actionTypes";

const WindowHeightReducer = (windowHeight = null, action) => {
  if (windowHeight == null) {
    return {};
  } if (action.type === SET_HEIGHT) {
    return action.payload;
  }
  return windowHeight;
};

export default WindowHeightReducer;
