import { withStyles } from "@material-ui/core";
import { isEmpty, isNil } from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveSoftLogin } from "../../actions";
import InnerHeight from "../helpers/InnerHeight";
import Loading from "../Loading";
import logInUser from "./LogInUser/LogInUser";
import i18n from "../../i18n/i18next";
import NextButton from "../Icons/NextButton";
import ActionPlanTopBar from "./ActionPlan/ActionPlanTopBar";
import { sendEvent, getSessionName } from "../helpers/GaProvider";

class BreakWeekNotification extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      match: {
        params: { actionPlanId, userId, stepId },
      },
      softLogin,
    } = this.props;
    logInUser(this.props);
    const sessionName = getSessionName(softLogin);
    if (!isEmpty(softLogin) && !isNil(softLogin)) {
      sendEvent(
        "ActionPlan: Opened",
        `{apID: ${actionPlanId}, apStep: ${stepId}, userID: ${userId}, sessionName: ${sessionName}}`
      );
    }
  }

  render() {
    const {
      actionPlanDetail,
      windowHeight,
      history,
      match,
      softLogin,
      classes,
    } = this.props;
    const { stepId } = match.params;

    if (isEmpty(actionPlanDetail) || isEmpty(softLogin)) {
      return <Loading />;
    }

    const readingLink = softLogin.activeChild.logLink;

    return (
      <div
        style={{
          height: `calc(${windowHeight - 58}px)`,
          overflow: "scroll",
        }}
      >
        <InnerHeight />
        <ActionPlanTopBar
          weekNo={stepId}
          step={parseInt(stepId, 10) - 1}
          strategyName={actionPlanDetail.actionPlan.title}
          showSteps={false}
        />
        <div className={classes.container}>
          <img
            className={classes.breakWeekImage}
            src="/images/breakWeek.png"
            alt="Bear and Stack of Books"
          />
          <h1 className={classes.h1}>
            {i18n.t("ActionPlan.Your child's session is on a break!")}
          </h1>
          <h2 className={classes.h2}>
            {i18n.t("Logging reading time this week is optional.")}
          </h2>
          <h2 className={classes.h2}>
            {i18n.t("ActionPlan.Enjoy your break!")}
          </h2>
          <NextButton
            buttonStyle={{ margin: 0 }}
            onClick={() => history.push(readingLink)}
            text={i18n.t("ActionPlan.Log reading time")}
            textColor="#fff"
          />
        </div>
      </div>
    );
  }
}

const styles = () => ({
  container: {
    textAlign: "center",
    margin: 16,
    borderRadius: 20,
    boxShadow: "3px 9px 14px rgba(0,0,0,0.25)",
    padding: 16,
  },
  h1: {
    color: "#016FB6",
    fontWeight: "bold",
    fontSize: 26,
    textAlign: "center",
    padding: 12,
  },
  h2: {
    color: "#68788A",
    fontWeight: "500",
    fontSize: 20,
    textAlign: "center",
    padding: 12,
  },
  h3: {
    color: "#68788A",
    fontWeight: "400",
    fontStyle: "italic",
    fontSize: 14,
    textAlign: "center",
    padding: 12,
  },
  noAPImage: {
    paddingLeft: 8,
    paddingRight: 8,
    width: "50%",
    margin: "0 auto",
  },
});

const mapStateToProps = (state) => ({
  actionPlanDetail: state.actionPlanDetail,
  softLogin: state.softLogin,
  windowHeight: state.windowHeight
});

export default withRouter(
  connect(mapStateToProps, {
    saveSoftLogin
  })(withStyles(styles)(BreakWeekNotification))
);
