import { isNil } from "ramda";
import { FETCH_ACTION_PLAN_DETAILS, SET_WE_DID_IT_ACTION_PLAN } from "../actionTypes";

const actionPlanReducer = (actionPlanDetail = null, action) => {
  if (actionPlanDetail == null) {
    return {};
  } if (action.type === FETCH_ACTION_PLAN_DETAILS) {
    return action.payload;
  } if (action.type === SET_WE_DID_IT_ACTION_PLAN && !isNil(actionPlanDetail)) {
    const { payload: { step, id } } = action;
    const tipHelpfulnessForStep = actionPlanDetail.tipHelpfulness.find(th => parseInt(th.step, 10) === parseInt(step, 10)) || {};
    const i = actionPlanDetail.tipHelpfulness.indexOf(tipHelpfulnessForStep);
    if (i === -1) {
      const newState = {
        ...actionPlanDetail,
        tipHelpfulness: [
          ...actionPlanDetail.tipHelpfulness,
          {
            id,
            step: parseInt(step, 10),
            weDidIt: true
          }
        ]
      };
      return newState;
    }
    actionPlanDetail.tipHelpfulness[i].weDidIt = true;
    return actionPlanDetail;
  }
  return actionPlanDetail;
};

export default actionPlanReducer;
