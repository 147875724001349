import i18n from "i18next";
import en from "./en/translation.json";
import es from "./es/translation.json";

i18n.init({
  interpolation: {
    // React already does escaping
    escapeValue: false
  },
  lng: "en", // 'en' | 'es'
  // Using simple hardcoded resources for simple example
  fallbackLng: "en",
  debug: true,
  resources: {
    en: {
      translation: en
    },
    es: {
      translation: es
    }
  }
});

export default i18n;
