export const feedbackFinishStyles = (theme) => ({
  radio: {
    padding: 10,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  inputLabel: {
    marginBottom: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  headerText: {
    textAlign: "center",
    lineHeight: "40px",
    color: "#000",
    "& img": {
      verticalAlign: "text-bottom"
    }
  },
  feedbackFinish: {
    // background: "url(/images/feedbackFinishBg.jpg) top center no-repeat",
    backgroundSize: "cover",
    color: "#000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(2),
    overflow: "scroll",
  },
  tapAnywhere: {
    color: "#000",
    fontSize: 16,
    marginTop: "auto",
    fontWeight: 500,
    zIndex: 9999,
  },
  rewardTimeline: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderRadius: 20,
    background: "#F7F5FF"
  },
  readingTipInfo: {
    fontSize: 20,
    color: "#000",
    fontWeight: 700,
    padding: theme.spacing(2),
    letterSpacing: "0.5px",
    lineHeight: "28px",
  },
  okBtn: {
    marginTop: "auto",
    textTransform: "none",
    fontWeight: 700,
    fontSize: 20,
    minHeight: 50,
    background: "#49BFF1",
    boxShadow: "0px 6px 0px #1CAFEE",
    borderRadius: 50,
    color: "#fff"
  },
  reminderBtn: {
    color: "#000",
    fontSize: 16,
    textTransform: "none",
    minHeight: 70,
    "& img": {
      width: 24,
      marginRight: theme.spacing(1),
    },
  },
  dialogTitle: {
    padding: 0,
  },
  dialogContent: {
    background: "#ffffff",
    borderRadius: "30px 30px 0 0",
    height: 430,
  },
  reminderTimer: {
    textAlign: "center",
    "& img": {
      padding: 20,
    },
  },
  backButtonContainer: {
    position: "fixed",
    bottom: 0,
    zIndex: 10,
    backgroundColor: "#ffffff",
    textAlign: "center",
    width: `calc(100vw - ${theme.spacing(6)}px)`,
  },
  backButton: {
    fontSize: 16,
    fontWeight: 400,
    textDecoration: "underline",
    textTransform: "none",
    margin: "10px auto",
  },
  when: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "19px",
    letterSpacing: "0.5px",
  },
  whenBody: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "17px",
    // letterSpacing: "0.5px"
  },
  selects: {
    display: "flex",
    marginTop: theme.spacing(1),
    justifyContent: "space-around",
  },
  selectsFreq: {
    display: "flex",
    marginTop: theme.spacing(2),
    justifyContent: "space-around",
  },

  timePicker: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#E7F5FF",
    border: "4px solid #DAE8F2",
    webkitAppearence: "none",
    fontSize: 16,
    fontWeight: 700,
    padding: "3px 10px 3px 12px",
    fontFamily: "Gotham A",
    "&:focus": {
      border: "4px solid #DAE8F2",
      backgroundColor: "#E7F5FF",
      borderRadius: 10,
    },
    "& div": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
});
