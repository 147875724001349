export const parentTheme = {
  Link: {
    textDecoration: "none"
  },
  palette: {
    primary: {
      light: "#90fb6d",
      main: "#5ac73c",
      mainGradient: "linear-gradient(90deg, #53c73c, #93c93e)",
      dark: "#189500",
      contrastText: "#ffffff",
      borderColor: "#BAD2E1"
    },
    secondary: {
      light: "#ff9959",
      main: "#EA682C",
      dark: "#b13800",
      contrastText: "#ffffff"
    },
    teacher: {
      extraLight: "#90CDF5",
      light: "#E7F5FF",
      main: "#016FB6",
      mainGradient: "linear-gradient(0deg, #016FB6, #388BC3)",
      dark: "#004481",
      contrastText: "#ffffff",
      borderColor: "#BAD2E1",
      superLight: "#F6FAFD"
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      "Gotham A",
      "-apple-system",
      "BlinkMacSystemFont",
      "\"Segoe UI\"",
      "Roboto",
      "\"Helvetica Neue\"",
      "Arial",
      "sans-serif",
      "\"Apple Color Emoji\"",
      "\"Segoe UI Emoji\"",
      "\"Segoe UI Symbol\""
    ].join(","),
    h2: {
      fontSize: 18,
      color: "#fff",
      fontWeight: 700
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
      padding: "15px 25px 25px"
    },
    h6: {
      fontSize: 20,
      fontWeight: 700,
      marginLeft: 10
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 700
    },
    body1: {
      fontSize: 16,
      lineHeight: "19px",
      fontWeight: 400,
      textAlign: "left",
      letterSpacing: "0.16px"
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: "left"
    }
  },
  overrides: {
    MuiButton: {}
  }
};
