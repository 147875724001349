import React from "react";
import {
  withStyles,
  // createTheme,
  // MuiThemeProvider,
  withTheme,
} from "@material-ui/core";

// import { parentTheme } from "./themes/parentTheme";
import i18n from "../i18n/i18next";

// const ptheme = createTheme(parentTheme);

const LoginError = (props) => {
  const { classes } = props;
  return (
    <div>
      <div className={classes.header}>
        <img
          src="/images/loginErrorIcon.png"
          alt="Login error icon"
          height="80"
        />
        <p className={classes.errorMessage}>{i18n.t("Error.errorMessage")}</p>
      </div>
      <div className={classes.loginErrorBody}>
        <img
          src="images/error-link.png"
          alt="go to the last message from springboard"
        />
      </div>
    </div>
  );
};

const styles = (theme) => ({
  loginErrorBody: {
    textAlign: "center",
    "& img": {
      textAlign: "center",
      maxWidth: 400,
    },
  },
  header: {
    background: "#016FB6",
    padding: theme.spacing(3),
    textAlign: "center",

    "& img": {
      textAlign: "center",
      padding: 30,
    },
  },
  errorMessage: {
    color: "#ffffff",
    fontWeight: 700,
    fontSize: 18,
    textAlign: "center",
  },
});

export default withTheme(withStyles(styles)(LoginError));
