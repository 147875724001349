import { SET_WIDTH } from "../actionTypes";

const WindowWidthReducer = (windowHeight = null, action) => {
  if (action.type === SET_WIDTH) {
    return action.payload;
  }
  return windowHeight;
};

export default WindowWidthReducer;
