import React, { Component } from "react";
import { withStyles, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import BaseButton from "../Icons/BaseButton";
import i18n from "../../i18n/i18next";

class MaintenanceView extends Component {
  state = {
    // lang: i18n.language
  };

  login = () => {
    const { history } = this.props;
    history.push("/educator");
  };

  currentLang = () => {
    // this.setState({ lang });
  };

  joinList = () => {
    window.location.href =
      "https://springboardcollaborative.freshdesk.com/support/tickets/new";
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div style={{ flex: 1, padding: 12 }}>
            <Link to="/">
              <img
                style={{ maxWidth: 250, width: "100%" }}
                src="/images/landing-page/sb-logo-blue.png"
                alt="Springboard Connect"
              />
            </Link>
          </div>
          <div style={{ flex: 1, padding: 12, textAlign: "right" }}>
            <Hidden smDown>
              <Link
                style={{ fontWeight: "bold", color: "#016FB6" }}
                target="_blank"
                to="//springboardcollaborative.org"
              >
                {i18n.t("Maintenance.Visit")} springboardcollaborative.org
              </Link>
            </Hidden>
            <Hidden mdUp>
              <Link
                style={{ fontWeight: "bold", color: "#016FB6", fontSize: 12 }}
                target="_blank"
                to="//springboardcollaborative.org"
              >
                springboardcollaborative.org
              </Link>
            </Hidden>
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            justifyContent: "center",
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <img
            className={classes.image}
            src="/images/landing-page/bunny.svg"
            alt="Bunny"
          />
          <img
            className={classes.image}
            src="/images/landing-page/tiger.svg"
            alt="Tiger"
          />
          <img
            className={classes.image}
            src="/images/landing-page/bear.svg"
            alt="Bear"
          />
          <img
            className={classes.image}
            src="/images/landing-page/girl.svg"
            alt="Girl"
          />
          <img
            className={classes.image}
            src="/images/landing-page/reindeer.svg"
            alt="Reindeer"
          />
        </div>
        <Hidden smDown>
          <h1 className={classes.h1}>
            {i18n.t("Maintenance.Bringing family and teacher")}
          </h1>
          <h2 className={classes.h2}>{i18n.t("Maintenance.Coming soon")}</h2>
        </Hidden>
        <Hidden mdUp>
          <h1 className={classes.h1} style={{ fontSize: 28 }}>
            {i18n.t("Maintenance.Bringing family and teacher")}
          </h1>
          <h2 className={classes.h2} style={{ fontSize: 16 }}>
            {i18n.t("Maintenance.Coming soon")}
          </h2>
        </Hidden>
        <BaseButton
          width={340}
          backgroundColor="#464646"
          shadowColor="#000"
          onClick={this.joinList}
          text={i18n.t("Maintenance.Join the mailing list")}
        />
        <p className={classes.content}>
          {i18n.t(
            "Maintenance.Already in a Springboard program or have an account"
          )}
        </p>
        <BaseButton
          width={340}
          onClick={this.login}
          color="#242424"
          text={i18n.t("Maintenance.Login")}
        />
      </div>
    );
  }
}

const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100vw",
    minHeight: "100vh",
    background: "#ffffff",
    alignItems: "center",
    paddingBottom: 70,
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: 10,
  },
  h1: {
    color: "#000",
    fontWeight: "bold",
    fontSize: 54,
    maxWidth: 1024,
    textAlign: "center",
    paddingLeft: 16,
    paddingRight: 16,
    margin: "0.5em",
  },
  h2: {
    color: "#000",
    fontWeight: "bold",
    fontSize: 24,
    maxWidth: 768,
    textAlign: "center",
    padding: 16,
    margin: "0.5em",
  },
  content: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    padding: 16,
  },
  image: {
    paddingLeft: 12,
    paddingRight: 12,
  },
});

export default withStyles(styles)(MaintenanceView);
