import React from "react";
import { Button } from "@material-ui/core";

const HelpIcon = (props) => {
  const {
    onClick = () => {},
    backgroundColor = "transparent",
    text = "Help",
    size = 60,
  } = props;

  const style = {
    minWidth: size,
    width: size,
    height: size,
    borderRadius: size / 2,
    backgroundColor,
    padding: 0,
    lineHeight: 1,
  };

  return (
    <Button onClick={onClick} style={style}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img src="/images/navigation/zoe.svg" alt="help icon" />
        <span
          style={{ fontSize: 12, textTransform: "capitalize", marginTop: 2 }}
        >
          {text}
        </span>
      </div>
    </Button>
  );
};

export default HelpIcon;
