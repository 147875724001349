import { isEmpty, isNil } from "ramda";
import ReactGA from "react-ga";
import { FCstore as store } from "../../store/FCstore";

const getGroups = () => {
  if (
    !isNil(store.getState().softLogin) &&
    !isEmpty(store.getState().softLogin)
  ) {
    const groups = JSON.stringify({
      id: store.getState().softLogin.activeChild.id,
      Gr: "v3", //store.getState().softLogin.activeChild.appVersion,
    });
    return groups;
  }
};

export const getSessionName = (softLogin) =>
  isNil(softLogin) ||
  isNil(softLogin.activeChild?.programs) ||
  softLogin.activeChild?.programs?.length <= 0
    ? "unknown"
    : softLogin.activeChild.programs[0].name;

export const getCurrentLocalTime = () => {
  // Get local time as ISO string with offset at the end
  const now = new Date();
  const tzo = -now.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = (num) => {
    const norm = Math.abs(Math.floor(num));
    return (norm < 10 ? "0" : "") + norm;
  };
  return `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(
    now.getDate()
  )}T${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(
    now.getSeconds()
  )}.${pad(now.getMilliseconds())}${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`;
};

export const sendEvent = (action, label) => {
  ReactGA.set({ dimension1: getCurrentLocalTime() });
  if (
    !isNil(store.getState().softLogin) &&
    !isEmpty(store.getState().softLogin)
  ) {
    ReactGA.set({
      dimension3: store.getState().softLogin.activeChild.language,
    });
  }
  ReactGA.event({
    category: getGroups(),
    action,
    label,
  });
};
