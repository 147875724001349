import React from "react";
import { withStyles, Button } from "@material-ui/core";

import i18n from "../../../i18n/i18next";

const SkipPopup = props => {
  const { classes, handleQuitButton, handleSkipBtn } = props;
  return (
    <div className={classes.container}>
      <img src="/images/skipReadingProgress.png" alt="Skip" height="90" />

      <h3 className={classes.h3}>{i18n.t("recordProgress.Are you sure?")}</h3>
      <p>{i18n.t("recordProgress.Recording progress allows you")}</p>
      <br />
      <Button
        id="BackToRecordProgressButton"
        onClick={handleSkipBtn}
        variant="contained"
        color="primary"
        style={{ color: "#000000", textTransform: "none", marginBottom: 10 }}
      >
        {i18n.t("recordProgress.Back to record progress")}
      </Button>
      <br />
      <Button
        id="QuitRecordProgressButton"
        onClick={handleQuitButton}
        variant="text"
        style={{ color: "#000000", textTransform: "none" }}
      >
        {i18n.t("recordProgress.skip")}
      </Button>
    </div>
  );
};

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    // width: '90%',
    // height: '90vh',
    textAlign: "center",
  },
  h3: {
    color: theme.palette.teacher.main,
    fontSize: 18,
    fontWeight: 700,
  },
});

export default withStyles(styles)(SkipPopup);
