import { withStyles } from "@material-ui/core";
import { isEmpty } from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveSoftLogin } from "../../actions";
import InnerHeight from "../helpers/InnerHeight";
import Loading from "../Loading";
import logInUser from "./LogInUser/LogInUser";
import i18n from "../../i18n/i18next";
import NextButton from "../Icons/NextButton";

class NoAP extends Component {
  componentDidMount() {
    logInUser(this.props);
  }

  render() {
    const { windowHeight, history, softLogin, classes } = this.props;

    if (isEmpty(softLogin)) {
      return <Loading />;
    }

    const readingLink = softLogin.activeChild.logLink;

    return (
      <div
        style={{
          height: `calc(${windowHeight - 58}px)`,
          overflow: "scroll",
        }}
      >
        <InnerHeight />
        <div className={classes.container}>
          <img
            className={classes.noAPImage}
            src="/images/noAP.png"
            alt="Teacher and Exclamation mark"
          />
          <h1 className={classes.h1}>
            {i18n.t(
              "ActionPlan.A specific strategy for your child is coming soon"
            )}
          </h1>
          <h2 className={classes.h2}>
            {i18n.t(
              "ActionPlan.While you wait, read together and use a reading tip from family workshops"
            )}
          </h2>
          <h3 className={classes.h3}>
            <img src="/images/lightbulb.png" alt="lightbulb" height="20" />
            {i18n.t("ActionPlan.Track your reading in the reading log")}
          </h3>
          <NextButton
            buttonStyle={{ margin: 0 }}
            onClick={() => history.push(readingLink)}
            text={i18n.t("ActionPlan.Log reading time")}
            textColor="#fff"
          />
        </div>
      </div>
    );
  }
}

const styles = () => ({
  container: {
    textAlign: "center",
    margin: 16,
    borderRadius: 20,
    boxShadow: "3px 9px 14px rgba(0,0,0,0.25)",
    padding: 16,
  },
  h1: {
    color: "#016FB6",
    fontWeight: "bold",
    fontSize: 26,
    textAlign: "center",
    padding: 12,
  },
  h2: {
    color: "#68788A",
    fontWeight: "500",
    fontSize: 20,
    textAlign: "center",
    padding: 12,
  },
  h3: {
    color: "#68788A",
    fontWeight: "400",
    fontStyle: "italic",
    fontSize: 14,
    textAlign: "center",
    padding: 12,
  },
  noAPImage: {
    paddingLeft: 8,
    paddingRight: 8,
    width: "50%",
    margin: "0 auto",
  },
});

const mapStateToProps = (state) => ({
  softLogin: state.softLogin,
  windowHeight: state.windowHeight,
});

export default withRouter(
  connect(mapStateToProps, {
    saveSoftLogin,
  })(withStyles(styles)(NoAP))
);
