import { isNil, isEmpty } from "ramda";
import ReactGA from "react-ga";
import vaultApi from "../../../apis/vaultApi";
import { getCurrentLocalTime } from "../../helpers/GaProvider";
import { fetchActionPlanDetail, saveSoftLogin } from "../../../actions";
import { FCstore } from "../../../store/FCstore";
import config from "../../../config";

const logIn = (props, apId = null, id = null, token = null) => {
  const { match } = props;
  const { actionPlanDetail, softLogin } = FCstore.getState();
  const { actionPlanId, userId, authToken } = match.params;

  vaultApi.get("check_parent_login").then((result) => {
    if (result.data.status === "ok") {
      if (
        isNil(actionPlanDetail) ||
        isEmpty(actionPlanDetail) ||
        parseInt(actionPlanDetail.id, 10) !== parseInt(apId || actionPlanId, 10)
      ) {
        FCstore.dispatch(
          fetchActionPlanDetail(
            apId || actionPlanId,
            id || userId,
            token || authToken
          )
        );
      }
      if (isNil(softLogin) || isEmpty(softLogin)) {
        FCstore.dispatch(saveSoftLogin(id || userId));
      }
    }
  });
};

const updateManifest = (props) => {
  const { match } = props;
  const { userId, authToken } = match.params;

  const link = document.createElement("link");
  link.href = `${config.apiUrl}/manifest/${userId}/${authToken}`;
  link.rel = "manifest";
  document.getElementsByTagName("head")[0].appendChild(link);
};

const logInUser = (props, actionPlanId = null, id = null, token = null) => {
  const { match } = props;
  const { userId, authToken } = match.params;
  vaultApi
    .post("try_parent_login", {
      student_id: id || userId,
      security_token: token || authToken,
    })
    .then((result) => {
      if (result.data.success === true) {
        logIn(props, actionPlanId, id, token);
        updateManifest(props);
      }
    })
    .catch((result) => {
      if (result.response.status === 401) {
        vaultApi.get("users/sign_out.json").then(() => {
          window.location.reload();
        });
      } else {
        window.location.replace("/login_error");
      }
    });

  ReactGA.set({ userId });
  ReactGA.set({ dimension1: getCurrentLocalTime() });
};

export default logInUser;
