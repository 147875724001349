import React from "react";
import { withStyles } from "@material-ui/core";
import i18n from "../i18n/i18next";

const styles = () => ({
  oops: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    verticalAlign: "middle",
    height: "100vh",
  },
});

const NotFound = (props) => {
  const { classes } = props;
  return (
    <div className={classes.oops}>{i18n.t("Error.Something went wrong")}</div>
  );
};

export default withStyles(styles)(NotFound);
