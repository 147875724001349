import React from "react";
import ActionPlan from "./ActionPlan";
import NoAP from "../NoAP";

const ActionPlanFactory = (props) => {
  const {
    match: {
      params: { actionPlanId, stepId },
    },
  } = props;
  if (parseInt(actionPlanId) === 0 && parseInt(stepId) === 0) {
    return <NoAP {...props} />;
  } else {
    return <ActionPlan {...props} />;
  }
};

export default ActionPlanFactory;
