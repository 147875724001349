
import { SAVE_SOFT_LOGIN, SET_PREVIEW } from "../actionTypes";
import i18n from "../i18n/i18next";

const SaveSoftLoginReducer = (softLogin = null, action) => {
  if (softLogin == null) {
    return {};
  } if (action.type === SAVE_SOFT_LOGIN) {
    i18n.changeLanguage(action.payload.activeChild.language);
    return action.payload;
  }
  if (action.type === SET_PREVIEW) {
    return {
      ...softLogin,
      activeChild: {
        ...softLogin.activeChild,
        clickedPreview: action.payload
      }
    };
  }

  return softLogin;
};


export default SaveSoftLoginReducer;
