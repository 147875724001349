import React from "react";
import { FormControl, NativeSelect } from "@material-ui/core";
import i18n from "../../../i18n/i18next";
import { isNil } from "ramda";

const RPTBooksSelect = (props) => {
  const { classes, onChange, selectedValue, label, disabled = false } = props;
  const val = isNil(selectedValue) || selectedValue > 10 ? "" : selectedValue;

  const books = i18n.t("recordProgress.books");

  return (
    <FormControl className={classes.formControl}>
      <NativeSelect
        variant="outlined"
        className={classes.select}
        aria-label={label}
        value={val}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
      >
        <option disabled value="">
          {i18n.t("recordProgress.Select")}
        </option>
        <option value="0">0 {books}</option>
        <option value="1">1 {i18n.t("recordProgress.book")}</option>
        <option value="2">2 {books}</option>
        <option value="3">3 {books}</option>
        <option value="4">4 {books}</option>
        <option value="5">5 {books}</option>
        <option value="6">6 {books}</option>
        <option value="7">7 {books}</option>
        <option value="8">8 {books}</option>
        <option value="9">9 {books}</option>
        <option value="10">10 {books}</option>
      </NativeSelect>
    </FormControl>
  );
};

export default RPTBooksSelect;
