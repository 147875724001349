import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isEmpty, isNil } from "ramda";
import { withRouter } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";

import isMobile from "react-device-detect";
import { withStyles } from "@material-ui/core";
import { saveSoftLogin } from "../../../actions";
import Loading from "../../Loading";
import ActionPlanTopBar from "./ActionPlanTopBar";
import InnerHeight from "../../helpers/InnerHeight";
import { sendEvent, getSessionName } from "../../helpers/GaProvider";
import { openChangeReminder } from "../ChangeReminderTime/actions";
import logInUser from "../LogInUser/LogInUser";
import ActionPlanStepV3 from "./ActionPlanStepV3";
import PageNotAvailable from "../PageNotAvailable";
import BreakWeekNotification from "../BreakWeekNotification";

class ActionPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: [true, false, false, false, false, false],
      shouldShowReminder: true,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { actionPlanId, userId, stepId },
      },
      softLogin,
    } = this.props;

    logInUser(this.props);
    this.triggerStepChange(stepId - 1);
    const sessionName = getSessionName(softLogin);
    if (!isEmpty(softLogin) && !isNil(softLogin)) {
      sendEvent(
        "ActionPlan: Opened",
        `{apID: ${actionPlanId}, apStep: ${stepId}, userID: ${userId}, sessionName: ${sessionName}}`
      );
    }
  }

  componentDidUpdate() {
    const {
      match: {
        params: { stepId },
      },
      actionPlanDetail,
      openChangeReminder,
      changeReminderTime,
      softLogin,
    } = this.props;
    const { shouldShowReminder } = this.state;
    let availableSteps = stepId;

    if (
      !isNil(actionPlanDetail) &&
      !isNil(actionPlanDetail.actionPlan) &&
      !isNil(actionPlanDetail.actionPlan.limitedContent)
    ) {
      availableSteps = actionPlanDetail.actionPlan.limitedContent.steps.length;
      if (availableSteps < stepId) {
        this.handleChangeIndex(availableSteps - 1);
      }
    }

    if (
      stepId < 2 &&
      shouldShowReminder &&
      !actionPlanDetail.archived &&
      !changeReminderTime.open &&
      !changeReminderTime.skip && // FIXME: Opens after review archived strategy and getting back to active one.
      !isEmpty(softLogin) &&
      softLogin.activeChild.appVersion !== "v5" &&
      softLogin.activeChild.appVersion !== "v6"
    ) {
      openChangeReminder(true);
    }
  }

  handleChangeIndex = (stepId) => {
    const { match, history } = this.props;
    const { actionPlanId, userId, authToken } = match.params;
    this.triggerStepChange(stepId);

    this.setState({ shouldShowReminder: false });

    history.push(
      `/family-companion/action-plans/${actionPlanId}/${
        parseInt(stepId, 10) + 1
      }/${userId}/${authToken}`
    );
  };

  triggerStepChange = (index) => {
    const { toggle } = this.state;
    const newState = toggle.map((x, idx) => idx <= index);
    this.setState({ toggle: newState });
  };

  closeRecordProgress = (skipId, skipped) => {
    const { match, softLogin } = this.props;
    const { actionPlanId, userId, stepId } = match.params;

    if (skipped) {
      const sessionName = getSessionName(softLogin);
      sendEvent(
        `Feedback Skip: step ${parseInt(skipId, 10) + 1}`,
        `{apID: ${actionPlanId}, apStep: ${stepId}, userID: ${userId}, sessionName: ${sessionName}}`
      );
    }
  };

  toggleShouldShowReminder = () => {
    this.setState((prevState) => ({
      shouldShowReminder: !prevState.shouldShowReminder,
    }));
  };

  goToGoal = () => {
    const { history, softLogin } = this.props;
    history.push(softLogin.activeChild.goalLink);
  };

  render() {
    const { actionPlanDetail, windowHeight, match, softLogin, classes } =
      this.props;
    const { userId, stepId, authToken } = match.params;
    const isArchived = actionPlanDetail.archived;

    if (isEmpty(actionPlanDetail) || isNil(actionPlanDetail) || isEmpty(softLogin)) {
      return <Loading />;
    }

    if (softLogin.activeChild.goalLink.includes("onboarding")) {
      this.goToGoal();
      return <Loading />;
    }

    const isPreviewMode = !softLogin.activeChild.programActive;
    if (!windowHeight) {
      return <div />;
    }

    if (actionPlanDetail.breakWeek) return <BreakWeekNotification {...this.props} />

    return (
      <div
        style={{ position: "relative", height: "100vh", overflow: "hidden" }}
      >
        <InnerHeight />
        <ActionPlanTopBar
          weekNo={stepId}
          step={parseInt(stepId, 10) - 1}
          strategyName={actionPlanDetail.actionPlan.title}
        />
        {!isMobile && (
          <Fragment>
            {parseInt(stepId, 10) > 1 && (
              <div
                className={`${classes.btnContainer} ${classes.left} `}
                style={{ alignSelf: "flex-end" }}
              >
                <div
                  variant="text"
                  className={`${classes.btn}  ${classes.btnLeft} `}
                  role="button"
                  aria-label="Previous"
                  tabIndex={0}
                  onClick={() =>
                    this.handleChangeIndex(parseInt(stepId, 10) - 2)
                  }
                  onKeyPress={() =>
                    this.handleChangeIndex(parseInt(stepId, 10) - 2)
                  }
                >
                  <svg
                    height="180"
                    viewBox="0 0 50 180"
                    width="50"
                    role="img"
                    aria-label="previous"
                  >
                    <path
                      d="M0 10v160c30.555-14.954 49.948-45.982 50-80-.052-34.018-19.445-65.046-50-80zm25.38 93.386c-.833.819-2.167.819-3 0l-11.75-11.844c-.839-.856-.839-2.227 0-3.084l11.7-11.844c.833-.819 2.167-.819 3 0 .839.856.839 2.227 0 3.084l-10.13 10.302 10.17 10.302c.847.851.851 2.227.01 3.084z"
                      fill="#000000"
                    />
                    <path
                      d="M22.33 76.614c.833-.819 2.167-.819 3 0 .839.856.839 2.227 0 3.084l-10.13 10.302 10.17 10.302c.847.852.851 2.227.01 3.084-.833.819-2.167.819-3 0l-11.75-11.844c-.839-.856-.839-2.227 0-3.084l11.7-11.844"
                      fill="#fff"
                    />
                  </svg>
                </div>
              </div>
            )}
            {parseInt(stepId, 10) < 6 && (
              <Fragment>
                <div
                  className={`${classes.btnContainer} ${classes.right} `}
                  style={{ alignSelf: "flex-end" }}
                >
                  <div
                    className={`${classes.btn} ${classes.btnRight} `}
                    role="button"
                    tabIndex={0}
                    aria-label="Next"
                    onClick={() => this.handleChangeIndex(parseInt(stepId, 10))}
                    onKeyPress={() =>
                      this.handleChangeIndex(parseInt(stepId, 10))
                    }
                  >
                    <svg
                      height="180"
                      viewBox="0 0 50 180"
                      width="50"
                      role="img"
                      aria-label="next"
                    >
                      <path
                        d="M0 90c.052 34.018 19.445 65.046 50 80v-160c-30.555 14.954-49.948 45.982-50 80zm24.63 10.302l10.17-10.302-10.13-10.302c-.839-.856-.839-2.227 0-3.084.833-.819 2.167-.819 3 0l11.7 11.844c.839.856.839 2.227 0 3.084l-11.75 11.844c-.833.819-2.167.819-3 0-.841-.857-.837-2.233.01-3.084z"
                        fill="#000000"
                      />
                      <path
                        d="M27.67 76.614l11.7 11.844c.839.856.839 2.227 0 3.084l-11.75 11.844c-.833.819-2.167.819-3 0-.841-.857-.837-2.232.01-3.084l10.17-10.302-10.13-10.302c-.839-.856-.839-2.227 0-3.084.833-.819 2.167-.819 3 0"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
        <SwipeableViews
          index={parseInt(stepId, 10) - 1}
          role="main"
          onChangeIndex={this.handleChangeIndex}
          containerStyle={{
            WebkitOverflowScrolling: "touch",
            height: `${windowHeight - 94 - 70}px`,
          }}
        >
          {actionPlanDetail.actionPlan.limitedContent.steps.map(
            (stepContent, index) => {
              let fba = {};
              const idx = index;

              const answers = actionPlanDetail.tipHelpfulness.filter(
                (tipHelpfulness) =>
                  parseInt(tipHelpfulness.step, 10) === parseInt(stepId, 10)
              );

              if (!isNil(answers[0])) {
                [fba] = answers;
              }
              return (
                <div key={idx}>
                  <ActionPlanStepV3
                    appVersion={softLogin.activeChild.appVersion}
                    stepContent={stepContent}
                    apStepId={idx}
                    title={actionPlanDetail.title}
                    actionPlanId={actionPlanDetail.actionPlan.id}
                    userId={userId}
                    tipHelpfulness={fba}
                    authToken={authToken}
                    isArchived={isArchived}
                  />
                </div>
              );
            }
          )}
        </SwipeableViews>
        {/* <ReadingLogV5 /> */}
        <PageNotAvailable
          onClose={this.goToGoal}
          isPreviewMode={isPreviewMode}
          windowHeight={windowHeight}
        />
        {/* <ChangeReminderTime /> */}
      </div>
    );

    // }
  }
}

const styles = () => ({
  btnsContainer: {
    width: "100%",
    position: "absolute",
    zIndex: 10,
    display: "flex",
    flexDirection: "row",
    marginTop: 116,
    justifyContent: "space-between",
  },
  btnContainer: {
    position: "absolute",
    zIndex: 10,
    marginTop: 116,
    overflow: "hidden",
    width: 50,
    "&:hover": {
      "& $btnLeft": {
        opacity: 1,
      },
      "& $btnRight": {
        opacity: 1,
      },
    },
  },
  left: {
    left: 0,
    marginLeft: -2,
    paddingRight: 4,
  },
  right: {
    right: 0,
    marginRight: -2,
  },

  btnLeft: {
    opacity: 0.3,
    transition: "opacity 0.3s",
  },
  btnRight: {
    opacity: 0.3,
    transition: "opacity 0.3s",
  },
  btn: {
    width: 50,
    height: 180,

    "&:focus": {
      // background: "#3dff00",
      border: "2px solid #000000",
    },
  },
});

const mapStateToProps = (state) => ({
  actionPlanDetail: state.actionPlanDetail,
  softLogin: state.softLogin,
  windowHeight: state.windowHeight,
  changeReminderTime: state.changeReminderTime,
});

export default withRouter(
  connect(mapStateToProps, { saveSoftLogin, openChangeReminder })(
    withStyles(styles)(ActionPlan)
  )
);
