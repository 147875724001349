export const topNavStyles = (theme) => ({
  drawer: {
    minWidth: "150px",
    width: "80vw",
    zIndex: "1500 !important",
  },
  drawerElement: {
    maxWidth: 768,
    margin: "0px auto",
    position: "relative",
    background: "transparent",
    zIndex: "1500 !important",
  },
  paper: {
    position: "relative",
    background: "transparent",
    zIndex: "1500 !important",
  },
  burger: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderLeft: `1px solid ${theme.palette.primary.borderColor}`,
  },
  burgerIcon: {
    color: "#016fb6",
    verticalAlign: "middle",
  },
  topNavNav: {
    // borderTop: "1px solid #016fb6",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  topNavItem: {
    textAlign: "center",
    background: "#e9f5fe",
    // borderRight: `1px solid #016fb6`,
    flexGrow: 1,
    "& button": {
      color: "#016fb6",
      textTransform: "none",
      // display: "inline-block",
      // margin: "0 auto",
      width: "100%",
    },
    "&:last-child": {
      border: "none",
    },
  },
  selected: {
    background: "#016fb6",
    "& button": {
      color: "#ffffff",
    },
  },
  topNavItemDisabled: {
    textAlign: "center",
    background: "rgb(100,168,211)",
    // borderRight: `1px solid #016fb6`,
    flexGrow: 1,
    "& a": {
      color: "#016fb6",
    },
    "&:last-child": {
      border: "none",
    },
  },
  topNavItemLink: {
    padding: 10,
    display: "flex",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: "bold",
    color: "#666666",
    textDecoration: "none",
  },
  stepIndicatorContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 8
  },
  topBar: {
    color: "#000",
    padding: theme.spacing(1),
    height: 94
  },
  header: {
    // padding: `${theme.spacing(2)}px 0`,
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    height: "62px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "4px solid #f5f5f5",
    padding: "0 8px 10px",
    marginLeft: "-8px",
    width: "calc(100% + 16px)",
  }
});
