import React from "react";
import { withStyles } from "@material-ui/core";

const StepIndicatior = props => {
  const { classes, toggled, index } = props;
  return (
    <div
      id={`StepBullet_${index}`}
      className={classes.step}
      style={{
        backgroundColor: toggled ? "#49BFF1" : "#dcdcdc", transition: "background 500ms"
      }}
    />
  );
};

const styles = {
  step: {
    margin: "5px 5px",
    borderRadius: 50,
    width: 23,
    height: 15,
    transition: "background 500ms",
    backgroundColor: "#fff",
  }
};

export default withStyles(styles)(StepIndicatior);
