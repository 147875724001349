import React from "react";
import { Button } from "@material-ui/core";

const BaseButton = (props) => {
  const {
    onClick = () => { },
    color = "#FFF",
    backgroundColor = "#49BFF1",
    shadowColor = "#1CAFEE",
    size = 50,
    text = "Next",
    width = "100%"
  } = props;

  const style = {
    minWidth: size,
    height: size,
    borderRadius: size / 2,
    width,
    color,
    backgroundColor,
    padding: 0,
    lineHeight: 1,
    margin: 10,
    fontSize: 22,
    textTransform: "capitalize",
    fontWeight: 700,
    display: "grid",
    boxShadow: `0px 5px 0px ${shadowColor}`
  };

  return (
    <Button onClick={onClick} style={style}>
      {text}
    </Button>
  );
};

export default BaseButton;
