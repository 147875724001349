import { CLEAR_REFLECT_FEEDBACK, SUBMIT_REFLECT_FEEDBACK } from "../actionTypes";

const reflectFeedbackReducer = (reflectFeedback = null, action) => {
  if (reflectFeedback == null) {
    return {};
  } if (action.type === SUBMIT_REFLECT_FEEDBACK) {
    return action.payload;
  } if (action.type === CLEAR_REFLECT_FEEDBACK) {
    return {};
  }
  return reflectFeedback;
};

export default reflectFeedbackReducer;
