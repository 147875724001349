/* eslint-disable indent */
import React from "react";
import { withStyles, Typography } from "@material-ui/core";

const styles = (theme) => ({
  timelineStep: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "16px 8px",
    position: "relative",
  },
  timelineStepIcon: {
    marginRight: theme.spacing(1),
    width: 30,
    textAlign: "center",
    flexShrink: 0,
  },
  timelineStepText: {
    color: "#242424"
  },
  on: {
    fontWeight: 500,
    color: "#000",
  },
  current: {
    fontWeight: 700,
    color: "#000",
  },
  off: {
    fontWeight: 500,
    color: "#242424",
  },
});

const TimelineStep = props => {
  const { classes, iconName, text, iconState } = props;
  let cn = "";
  switch (iconState) {
    case "on":
      cn = classes.on;
      break;
    case "current":
      cn = classes.off;
      break;
    default:
      cn = classes.off;
      break;
  }
  return (
    <div className={classes.timelineStep}>
      <div className={classes.timelineStepIcon}>
        <img
          width="22"
          src={`/images/icons/${iconName}.png`}
          alt="reward timeline icon"
        />
      </div>
      <div className={`${classes.timelineStepText}`}>
        <Typography variant="body1" className={cn}>
          {text}
        </Typography>
      </div>
    </div>
  );
};


export default withStyles(styles)(TimelineStep);
