import React, { Component } from "react";
import { withStyles, Paper, Dialog, Button } from "@material-ui/core";

import { connect } from "react-redux";
import i18n from "../../../i18n/i18next";
import { TransitionDown } from "../../helpers/Transition";

const CustomPaper = withStyles(() => ({
  root: {
    margin: 24,
  },
}))(Paper);

class NetworkIssuePopup extends Component {
  state = {
    isRetrying: false
  }

  retryRequests = (functions) => {
    const { isRetrying } = this.state;
    if (!isRetrying) {
      this.setState({ isRetrying: true }, () => {
        functions.forEach(func => func());
        setTimeout(() => {
          this.setState({ isRetrying: false });
        }, 5000);
      });
    }
  };

  render() {
    const { classes, networkError } = this.props;
    const { isRetrying } = this.state;
    return (
      <Dialog
        PaperComponent={CustomPaper}
        open={networkError.open}
        TransitionComponent={TransitionDown}
      >
        <div className={classes.container}>
          <p>{i18n.t("recordProgress.Network problem")}</p>
          <br />
          <Button
            onClick={() => this.retryRequests(Object.values(networkError.funcs))}
            variant="contained"
            color="primary"
            style={{ color: "#000000", textTransform: "none", marginBottom: 10 }}
            disabled={isRetrying}
          >
            {isRetrying ? i18n.t("recordProgress.Reconnecting") : i18n.t("recordProgress.Try again")}
          </Button>
        </div>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    // width: '90%',
    // height: '90vh',
    textAlign: "center",
  },
  h3: {
    color: theme.palette.teacher.main,
    fontSize: 18,
    fontWeight: 700,
  },
  reconnecting: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    lineHeight: "24px"
  }
});

const mapStateToProps = state => ({
  networkError: state.networkError
});

export default connect(mapStateToProps, {})(withStyles(styles)(NetworkIssuePopup));
