import React from "react";
import { withStyles, Typography, Button } from "@material-ui/core";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { isNil } from "ramda";
import TimelineStep from "./TimelineStep";
import TimelineStepReward from "./TimelineStepReward";

import { feedbackFinishStyles } from "./feedbackFinishStyles";

import i18n from "../../../i18n/i18next";

import { openChangeReminder } from "../ChangeReminderTime/actions";

const styles = (theme) => feedbackFinishStyles(theme);

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const timelineStepIcons = [
  i18n.t("Feedback.learn"),
  i18n.t("Feedback.learn"),
  i18n.t("Feedback.ask"),
  i18n.t("Feedback.interact"),
  i18n.t("Feedback.practice"),
  i18n.t("Feedback.practice"),
];
const timelineStepText = [
  "Learn the strategy",
  "Dig deeper",
  "Ask your child",
  "Interact & play",
  "Practice in the real world",
  "Keep reading!",
];

class FeedbackFinish extends React.Component {
  handleChangeReminderOpen = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.openChangeReminder(true);
  };

  handleDialogFinishClose = () => {
    const { match, history } = this.props;
    const { actionPlanId, stepId, userId, authToken } = match.params;
    history.push(
      `/family-companion/action-plans/${actionPlanId}/${stepId}/${userId}/${authToken}`
    );
  };

  render() {
    const { classes, windowHeight, changeReminderTime, match, softLogin } =
      this.props;

    const { stepId } = match.params;

    const { reminderDay, reminderTime, reminderFrequency } = changeReminderTime;

    if (isNil(reminderTime)) {
      return <div />;
    }

    const reminderFreqText =
      reminderFrequency === "daily"
        ? i18n.t("Feedback.Youll get your new reading tip every day at")
        : i18n.t("Feedback.Youll get your new reading tip next at", {
            day: i18n.t(`Feedback.${days[reminderDay]}`),
          });

    return (
      <div
        className={classes.feedbackFinish}
        style={{ height: `${parseInt(windowHeight, 10) - 32}px` }}
      >
        <Typography variant="h2" className={classes.headerText}>
          {i18n.t("Feedback.Your Checklist")}{" "}
          <img
            src="/images/icons/set_your_goal.png"
            alt="set your goal"
            width="40"
          />
        </Typography>
        <div id="ChecklistContainer" className={classes.rewardTimeline}>
          {timelineStepText.map((timelineStep, index) => {
            let iconState = "off";
            if (stepId > index) {
              iconState = "on";
            } else if (stepId === index) {
              iconState = "current";
            }
            const iconName = `${timelineStepIcons[index]}_${iconState}`;
            const key = stepId + index;
            return (
              <TimelineStep
                key={key}
                iconName={iconName}
                text={i18n.t(`Feedback.${timelineStep}`)}
                iconState={iconState}
              />
            );
          })}
          <TimelineStepReward text="[Reward]" step={stepId} />
        </div>

        <Typography
          variant="h2"
          align="center"
          className={classes.readingTipInfo}
        >
          {reminderFreqText}{" "}
          <Moment parse="h:mm A" format="h:mm A">
            {reminderTime.toString()}
          </Moment>
        </Typography>

        {softLogin.activeChild.appVersion !== "v3" && (
          <Button
            variant="text"
            onClick={this.handleChangeReminderOpen}
            className={classes.reminderBtn}
          >
            <img
              src="/images/clockBtnIcon.png"
              alt={`${i18n.t("Feedback.Change reminder time")} icon`}
            />
            {i18n.t("Feedback.Change reminder time")}
          </Button>
        )}
        <Button
          id="OkayButton"
          variant="contained"
          fullWidth
          onClick={this.handleDialogFinishClose}
          className={classes.okBtn}
        >
          {i18n.t("Feedback.Okay!")}
        </Button>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  feedbackReward: state.feedbackReward,
  studentDetails: state.studentDetails,
  softLogin: state.softLogin,
  windowHeight: state.windowHeight,
  changeReminderTime: state.changeReminderTime,
});

export default withRouter(
  connect(mapStateToProps, {
    openChangeReminder,
  })(withStyles(styles)(FeedbackFinish))
);
