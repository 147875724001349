import { CLOSE_STARS_INCREASE, OPEN_STARS_INCREASE, SET_STARS_INCREASE_DATA } from "./actionTypes";

export const closeStarsIncrease = () => async dispatch => {
  dispatch({ type: CLOSE_STARS_INCREASE, payload: {} });
};

export const openStarsIncrease = () => async dispatch => {
  dispatch({ type: OPEN_STARS_INCREASE, payload: {} });
};

export const setStarsIncreaseData = (message, image, max, stars, totalStars, link) => async dispatch => {
  dispatch({ type: SET_STARS_INCREASE_DATA, payload: { message, image, max, stars, totalStars, link } });
};
