import React from "react";

import { Typography, withStyles } from "@material-ui/core";

import i18n from "../../../i18n/i18next";

const styles = (theme) => ({
  neutralAnim: {
    animation: "neutralAnim 1s cubic-bezier(0.680, -0.550, 0.265, 1.550) both",
  },
  "@keyframes neutralAnim": {
    from: {
      transform: "scale(0)",
      opacity: 0,
    },
    to: {
      transform: "scale(1)",
      opacity: 1,
    },
  },
  neutralScreen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // minHeight: '85vh',
    position: "relative",
  },
  greatNewsImg: {
    width: 200,
    marginTop: "20%",
    marginBottom: theme.spacing(2),
  },
  greatNews: {
    fontWeight: 700,
    lineHeight: "1.3",
    letterSpacing: "0.5px",
    color: "#000",
    fontSize: 20,
    position: "relative",
    zIndex: 999999,
  },
});
const NeutralScreen = props => {
  const { classes, name, minutes, percents } = props;
  return (
    <div className={classes.neutralScreen}>
      <img
        src="/images/FeedbackNeutralFace.png"
        alt="feedback smile"
        className={`${classes.greatNewsImg} ${classes.neutralAnim}`}
      />
      <Typography
        className={classes.greatNews}
        variant="body1"
        align="center"
      >
        {percents === null
          ? i18n.t("Feedback.neutral2", { name, minutes })
          : parseInt(percents, 10) === 0
            ? i18n.t("Feedback.neutral1", { name, minutes })
            : i18n.t("Feedback.neutral3", {
              name,
              minutes,
              percents,
            })}
        <br />
        {/* {i18n.t("Feedback.neutral1", { name: name })} */}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(NeutralScreen);
