import React from "react";

const ReadingIcon = (props) => {
  const { fill = "#2F6EBB" } = props;

  return (
    <svg width="23" height="24" role="img" aria-label="Reading Icon" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.85716 0.228516H20.5714C21.8286 0.228516 22.8572 1.25709 22.8572 2.51423V16.2285C22.8572 17.4857 21.8286 18.5142 20.5714 18.5142H6.85716C5.60002 18.5142 4.57144 17.4857 4.57144 16.2285V2.51423C4.57144 1.25709 5.60002 0.228516 6.85716 0.228516ZM0 5.94281C0 5.31162 0.511675 4.79995 1.14286 4.79995C1.77404 4.79995 2.28571 5.31162 2.28571 5.9428V18.7999C2.28571 19.9045 3.18114 20.7999 4.28571 20.7999H17.1429C17.774 20.7999 18.2857 21.3116 18.2857 21.9428C18.2857 22.574 17.774 23.0857 17.1429 23.0857H2.28571C1.02857 23.0857 0 22.0571 0 20.7999V5.94281ZM16 2.51423C16.6312 2.51423 17.1429 3.0259 17.1429 3.65708V7.18955C17.1429 7.61763 16.6542 7.86198 16.3117 7.60514C16.127 7.46661 15.873 7.46661 15.6883 7.60514C15.3459 7.86198 14.8572 7.61763 14.8572 7.18955V3.65708C14.8572 3.0259 15.3688 2.51423 16 2.51423ZM6.85714 14.2285C6.85714 15.3331 7.75257 16.2285 8.85714 16.2285H18.5714C19.676 16.2285 20.5714 15.3331 20.5714 14.2285V3.08565C20.5714 2.77006 20.3156 2.51423 20 2.51423C19.6844 2.51423 19.4286 2.77006 19.4286 3.08565V9.68306C19.4286 10.9673 17.9624 11.7003 16.9351 10.9298C16.3809 10.5142 15.619 10.5142 15.0649 10.9298C14.0376 11.7003 12.5714 10.9673 12.5714 9.68306V4.51423C12.5714 3.40966 11.676 2.51423 10.5714 2.51423H8.85714C7.75257 2.51423 6.85714 3.40966 6.85714 4.51423V14.2285Z" fill={fill} />
    </svg>
  );
};

export default ReadingIcon;
