import React, { Component } from "react";
import { withStyles, Button } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { connect } from "react-redux";
import { isEmpty, isNil } from "ramda";
import { withRouter } from "react-router-dom";
import i18n from "../../../i18n/i18next";
import { saveSoftLogin } from "../../../actions";
import Loading from "../../Loading";
import InnerHeight from "../../helpers/InnerHeight";
import RecordProgressV3 from "../Feedback/RecordProgressV3";
import { sendEvent, getSessionName } from "../../helpers/GaProvider";
import SuccessScreen from "../Feedback/SuccessScreen";
import WorriedScreen from "../Feedback/WorriedScreen";
import NeutralScreen from "../Feedback/NeutralScreen";
import logInUser from "../LogInUser/LogInUser";
import ActionPlanTopBar from "../ActionPlan/ActionPlanTopBar";
import TempTiger from "../ReadingLogV5/tempTiger";

class GoalV3 extends Component {
  state = {
    showRecordProgress: false,
    tipsOpen: false,
    // skipped: false,
    showReward: false,
  };

  componentDidMount() {
    logInUser(this.props);
    this.setState({ tipsOpen: false });
  }

  componentDidUpdate() {
    const { softLogin } = this.props;
    const { showRecordProgress, skipped } = this.state;
    if (!isEmpty(softLogin)) {
      if (showRecordProgress !== softLogin.activeChild.showRecordProgress) {
        if (!skipped) {
          this.doShowRecordProgress();
        }
      }
    }
  }

  doShowRecordProgress = () => {
    const { softLogin } = this.props;
    this.setState({
      showRecordProgress: softLogin.activeChild.showRecordProgress,
    });
  };

  onStrategyClick = () => {
    const { match, softLogin } = this.props;
    const { actionPlanId, userId, stepId } = match.params;

    const sessionName = getSessionName(softLogin);

    sendEvent(
      "Goal: Checklist Button",
      `{apID: ${actionPlanId}, apStep: ${stepId}, userID: ${userId}, sessionName: ${sessionName}}`
    );
    this.setState((prevState) => ({
      tipsOpen: !prevState.tipsOpen,
    }));
  };

  calculateRewardScreen = (result, name, minutes, percent) => {
    const averageMinutes = parseInt(minutes, 10);

    if (result === "success") {
      return (
        <SuccessScreen
          name={name}
          percents={percent}
          minutes={averageMinutes}
        />
      );
    }
    if (result === "neutral") {
      return (
        <NeutralScreen
          name={name}
          percents={percent}
          minutes={averageMinutes}
        />
      );
    }
    return (
      <WorriedScreen name={name} percents={percent} minutes={averageMinutes} />
    );
  };

  onGotItClick = () => {
    const { match, softLogin, history } = this.props;
    const { actionPlanId, userId, stepId } = match.params;
    const link = softLogin.activeChild.practiceLink;
    const sessionName = getSessionName(softLogin);

    sendEvent(
      "Goal: Got It!",
      `{apID: ${actionPlanId}, apStep: ${stepId}, userID: ${userId}, sessionName: ${sessionName}}`
    );
    history.push(link);
  };

  hideReward = () => {
    this.setState({ showReward: false });
  };

  closeRecordProgress = (skipId, skipped) => {
    const { match, softLogin } = this.props;
    const { actionPlanId, userId, stepId } = match.params;
    const sessionName = getSessionName(softLogin);

    if (skipped) {
      sendEvent(
        `Feedback Skip: step ${parseInt(skipId, 10) + 1}`,
        `{apID: ${actionPlanId}, apStep: ${stepId}, userID: ${userId}, sessionName: ${sessionName}}`
      );
    }
    this.setState({
      showRecordProgress: false,
    });
  };

  skipRecordProgress = () => {
    this.setState({ skipped: true });
  };

  hideRecordProgress = () => {
    this.setState({ showRecordProgress: false });
  };

  render() {
    const {
      actionPlanDetail,
      windowHeight,
      softLogin,
      classes,
      feedbackReward,
      match,
      networkError,
    } = this.props;
    const { actionPlanId, stepId } = match.params;
    const { tipsOpen, showReward, showRecordProgress } = this.state;

    if (
      isEmpty(actionPlanDetail) ||
      isNil(actionPlanDetail) ||
      isEmpty(softLogin) ||
      networkError.open
    ) {
      return <Loading />;
    }
    if (!isNil(feedbackReward) && !isEmpty(feedbackReward) && showReward) {
      return <TempTiger open link={softLogin.activeChild.practiceLink} />;
    }
    if (showRecordProgress) {
      return (
        <RecordProgressV3
          closeRecordProgress={this.closeRecordProgress}
          actionPlanId={actionPlanId}
          stepId={stepId}
          hideRecordProgress={this.hideRecordProgress}
          skipRP={this.skipRecordProgress}
        />
      );
    }
    return (
      <div
        className={classes.container}
        style={{ height: `calc(${windowHeight}px)` }}
      >
        <InnerHeight />
        <ActionPlanTopBar
          strategyName={actionPlanDetail.actionPlan.title}
          showSteps={false}
        />
        <div
          className={classes.goalContainer}
          style={{ height: `calc(${windowHeight}px - 218px)` }}
        >
          <img
            style={{ marginTop: "20px" }}
            height="200"
            // width="88"
            src="/images/feedback_image_3.png"
            alt="Family reading"
          />
          <br />
          <span className={classes.goalHeader} id="GoalHeader">
            {i18n.t("Goal.GoalShort", {
              firstName: softLogin.activeChild.firstName,
            })}{" "}
            <img
              src="/images/goal-settings-icon.png"
              alt="Goal setting icon"
              height="36"
            />
          </span>
          <div id="GoalQuestion" className={classes.goalQuestion}>
            {actionPlanDetail.actionPlan.feedbackContent.questions[0].question}
          </div>
          <div className={classes.tipsContainer}>
            <Button
              id="ChecklistButton"
              className={classes.tipsBtn}
              onClick={this.onStrategyClick}
            >
              {/* <img src="/images/bulbIcon.png" height="26" alt="Bulb Icon" /> */}
              <span>
                {i18n.t("Goal.See goal checklist", {
                  firstName: softLogin.activeChild.firstName,
                })}
              </span>
              {!tipsOpen && <ArrowDropDown />}
              {tipsOpen && <ArrowDropUp />}
            </Button>
            {tipsOpen && (
              <ul id="TipsList" className={classes.tips}>
                <li>{i18n.t("Goal.tip1")}</li>
                <li>
                  {i18n.t("Goal.tip2", {
                    strategy: actionPlanDetail.actionPlan.title,
                  })}
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className={classes.btnCont}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.gotItBtn}
            onClick={this.onGotItClick}
            id="GotItButton"
          >
            {i18n.t("Goal.Got it")}
          </Button>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  btnCont: {
    padding: "8px 24px 16px",
  },
  goalContainer: {
    padding: "0 24px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    backgroundSize: "cover",
    overflowX: "hidden",
    overflowY: "scroll",
  },
  goalHeader: {
    fontSize: 18,
    fontWeight: 700,
    display: "block",
    width: "100%",
    textAlign: "left",
    color: "#000",
    verticalAlign: "center",
    lineHeight: "36px",
    "& img": {
      verticalAlign: "sub",
    },
  },
  goalQuestion: {
    // border: "3px solid #96C93E",
    // borderRadius: 10,
    // background: "#ffffff",
    // fontSize: 16,
    // fontWeight: 700,
    color: "#000",
    // boxShadow: "0 -2px 3px rgba(0,0,0,0.3)",
    // padding: theme.spacing(2),
    // textAlign: "center",
    // lineHeight: "22px",
    marginTop: theme.spacing(1),
    width: "100%",
    boxSizing: "border-box",

    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22px",
    background: "#F6F6F6",
    boxShadow: "1.52778px 3.05556px 3.81945px rgba(0, 0, 0, 0.15)",
    borderRadius: 6,
    padding: 8,
  },
  tipsBtn: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 700,
    textTransform: "none",
    textAlign: "left",
    color: "#000",
    width: "100%",
    // marginTop: theme.spacing(1),
    "& img": {
      marginRight: theme.spacing(2),
    },
  },
  tipsContainer: {
    border: "1px solid #000",
    borderRadius: 8,
    marginTop: 12,
    width: "100%",
    // padding: 10
  },
  tips: {
    // margin: theme.spacing(1),
    listStyle: "decimal outside none",
    paddingLeft: theme.spacing(3),

    "& li": {
      fontSize: 14,
      marginBottom: theme.spacing(1),
      lineHeight: "20px",
      fontWeight: 700,
      textTransform: "none",
      textAlign: "left",
      color: "#000",
    },
  },
  gotItBtn: {
    color: "#000",
    textTransform: "none",
    border: "0 transparent !important",
    background: "#49BFF1",
    boxShadow: "0px 6px 0px #1CAFEE",
    borderRadius: 50,
    fontWeight: 700,
    fontSize: 24,
  },
  feedbackFinish: {
    background: "url(/images/feedbackBg.png) bottom center no-repeat",
    backgroundSize: "contain",
    color: "#000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(2),
    overflow: "scroll",
    boxSizing: "border-box",
  },
  tapAnywhere: {
    color: "#000",
    fontSize: 16,
    marginTop: "auto",
    fontWeight: 500,
    zIndex: 9999,
  },
});

const mapStateToProps = (state) => ({
  feedbackReward: state.feedbackReward,
  actionPlanDetail: state.actionPlanDetail,
  softLogin: state.softLogin,
  windowHeight: state.windowHeight,
  networkError: state.networkError,
});

export default withRouter(
  connect(mapStateToProps, {
    saveSoftLogin,
  })(withStyles(styles)(GoalV3))
);
