import React, { Fragment } from "react";
import { withStyles, Dialog } from "@material-ui/core";
import { isEmpty, isNil } from "ramda";

// import vault from '../../../apis/vaultApi';
import { withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";

import { fetchActionPlanDetail, setWeDidIt } from "../../../actions";
import { headerHeight } from "./headerHeight";
import Feedback from "../Feedback/Feedback";
import FeedbackFinish from "../Feedback/FeedbackFinish";
import ActionPlanCardV3 from "./ActionPlanCardV3";
import i18n from "../../../i18n/i18next";
import { sendEvent, getSessionName } from "../../helpers/GaProvider";
import { TransitionDown } from "../../helpers/Transition";
import { Colors } from "../../themes/Colors";
import vaultApi from "../../../apis/vaultApi";
import NextButton from "../../Icons/NextButton";

const styles = (theme) => ({
  stepContainer: {
    overflow: "scroll",
  },
  step: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  stepImage: {
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: "#ffffff",
  },
  typo: {
    fontWeight: 700,
    marginLeft: 10,
  },
  grow: {
    flexGrow: 1,
  },
  feedbackButton: {
    background: theme.palette.primary.mainGradient,
    boxShadow: "0 3px 0 #48A02D",
    color: "#000",
    textTransform: "none",
    fontSize: 18,
    border: "0 transparent !important",
    "& img": {
      marginRight: theme.spacing(2),
      width: "25px",
    },
  },
  feedbackButtonDisabled: {
    // background: theme.palette.primary.mainGradient,
    boxShadow: "0 3px 0 #48A02D",
    color: "#000",
    textTransform: "none",
    fontSize: 18,
    border: "0 transparent !important",
    "& img": {
      marginRight: theme.spacing(2),
      width: "25px",
      opacity: "0.25",
    },
  },
  youtube: {
    width: "100%",
    "& iframe": {
      width: "100%",
      height: "calc(100% / 1.68)",
    },
  },
  feedbackBottom: {
    padding: "8px 16px 16px",
    background: "#ffffff",
    position: "fixed",
    bottom: 0,
    zIndex: 9,
    width: `calc(100% - ${theme.spacing(4)}px)`,
  },
  tipContainer: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  ribbon: {
    animation: "$ribbon 1s cubic-bezier(0.680, -0.550, 0.265, 1.550) both",
    position: "absolute",
    top: "10vh",
    width: "100%",
    height: "100vh",
    textAlign: "center",
    "& img": {
      textAlign: "center",
      maxWidth: "60%",
    },
  },
  "@keyframes ribbon": {
    from: {
      transform: "scale(0)",
      opacity: 0,
    },
    to: {
      transform: "scale(1)",
      opacity: 1,
    },
  },
  tipQuestion: {
    fontWeight: 700,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 15,
  },
  tipHelpfulness: {
    padding: 5,
    backgroundColor: Colors[0].light,
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  thumb: {
    "& img": {
      maxHeight: "100%",
      width: "auto",
      objectFit: "contain",
    },
    height: 24,
    "-webkit-appearence": "none",
    appearence: "none",
    background: "transparent",
    border: "0",
    outline: "none",
  },
  thumbSelected: {
    "& img": {
      maxHeight: "100%",
      width: "auto",
      objectFit: "contain",
    },
    height: 24,
    "-webkit-appearence": "none",
    appearence: "none",
    background: "#BAD2E1",
    border: "0",
    outline: "none",
  },
  maxWidthPaper: {
    maxWidth: 768,
  },
});

class ActionPlanStepV3 extends React.Component {
  constructor(props) {
    super(props);
    this.myFeedback = React.createRef();
    this.state = {
      showRibbon: false,
    };
  }

  createOrUpdateTipHelpfulness = (key, value) => {
    const {
      match: {
        params: { actionPlanId, stepId, userId, authToken },
      },
      tipHelpfulness,
      fetchActionPlanDetail,
      // setWeDidIt,
      softLogin: {
        activeChild: {
          currentApa: { apaId },
        },
      },
    } = this.props;

    if (!isNil(tipHelpfulness) && !isEmpty(tipHelpfulness)) {
      const params = {};
      params[key] = value;

      return vaultApi
        .patch(`/tip_helpfulness/${tipHelpfulness.id}`, params)
        .then(() => {
          if (key === "we_did_it") {
            // setWeDidIt(stepId, tipHelpfulness.id);
          } else {
            fetchActionPlanDetail(actionPlanId, userId, authToken);
          }
        });
    }
    const params = {
      assignment_id: apaId,
      step: stepId,
    };
    params[key] = value;

    return vaultApi.post("/tip_helpfulness", params).then(() => {
      if (key === "we_did_it") {
        // setWeDidIt(stepId, response.data.id);
      } else {
        fetchActionPlanDetail(actionPlanId, userId, authToken);
      }
    });
  };

  onFeedbackClick = () => {
    const { match, history, softLogin } = this.props;
    const { actionPlanId, stepId, userId } = match.params;
    const {
      activeChild: { logLink },
    } = softLogin;
    const sessionName = getSessionName(softLogin);

    sendEvent(
      "Feedback: Log reading time",
      `{apID: ${actionPlanId}, apStep: ${stepId}, userId: ${userId}, sessionName: ${sessionName}}`
    );

    this.setState({ showRibbon: true }, () => {
      setTimeout(() => {
        this.setState({ showRibbon: false });
        history.push(logLink);
        this.forceUpdate();
      }, 1500);
    });
  };

  handleThumbClick = (selectedValue) => {
    const {
      tipHelpfulness: { value },
    } = this.props;
    if (selectedValue === value) {
      this.createOrUpdateTipHelpfulness("value", null);
    } else {
      this.createOrUpdateTipHelpfulness("value", selectedValue);
    }
  };

  render() {
    const {
      classes,
      stepContent,
      apStepId,
      tipHelpfulness,
      windowHeight,
      actionPlanDetail,
      // softLogin,
      match,
    } = this.props;
    const { showRibbon } = this.state;
    let stepHeight = windowHeight - headerHeight - 17 - 90;
    const isArchived = actionPlanDetail.archived;
    stepHeight = isArchived ? stepHeight + 17 : stepHeight;
    return (
      <Fragment>
        <div
          className={classes.stepContainer}
          style={{ height: `${stepHeight < 85 ? 85 : stepHeight}px` }}
        >
          <ActionPlanCardV3 stepContent={stepContent} />
        </div>
        {showRibbon && (
          <div className={classes.ribbon}>
            <img src="/images/ribbon.png" alt="ribbon" />
          </div>
        )}

        {!isArchived && (
          <div ref={this.myFeedback} className={classes.feedbackBottom}>
            {/* <div className={classes.tipHelpfulness}>
              <span className={classes.tipQuestion}>{i18n.t("ActionPlan.Was this tip helpful")}</span>
              <button
                id={`ThumbUp_${apStepId + 1}`}
                type="button"
                className={tipHelpfulness.value === "up" ? classes.thumbSelected : classes.thumb}
                onClick={() => this.handleThumbClick("up")}
              >
                <img src="/images/thumbUp.png" alt="Thumb Up" />
              </button>
              <button
                id={`ThumbDown_${apStepId + 1}`}
                type="button"
                className={
                  tipHelpfulness.value === "down" ? classes.thumbSelected : classes.thumb
                }
                onClick={() => this.handleThumbClick("down")}
              >
                <img src="/images/thumbDown.png" alt="Thumb Down" />
              </button>
            </div> */}
            <NextButton
              buttonStyle={{ margin: 0 }}
              onClick={this.onFeedbackClick}
              isDisabled={apStepId !== match.params.stepId - 1}
              // isDisabled={tipHelpfulness.weDidIt === true}
              text={i18n.t("ActionPlan.Log reading time")}
              textColor="#000"
            />
            <Route
              path={`${match.path}/feedback/:feedbackStep`}
              render={(props) => {
                const { stepId } = props.match.params;
                if (parseInt(stepId, 10) !== parseInt(apStepId, 10) + 1) {
                  return false;
                }
                return (
                  <Feedback
                    apStepId={apStepId}
                    feedbackAnswers={
                      isEmpty(tipHelpfulness) ? false : tipHelpfulness.value
                    }
                  />
                );
              }}
            />
          </div>
        )}

        {!isArchived && (
          <Route
            path={`${match.path}/done`}
            render={(props) => {
              const { stepId } = props.match.params;
              if (parseInt(stepId, 10) !== parseInt(apStepId, 10) + 1) {
                return false;
              }
              return (
                <Dialog
                  fullScreen
                  open
                  TransitionComponent={TransitionDown}
                  classes={{
                    paper: classes.maxWidthPaper,
                  }}
                >
                  <FeedbackFinish />
                </Dialog>
              );
            }}
          />
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  windowHeight: state.windowHeight,
  softLogin: state.softLogin,
  actionPlanDetail: state.actionPlanDetail,
});

export default withRouter(
  withStyles(styles)(
    connect(mapStateToProps, { fetchActionPlanDetail, setWeDidIt })(
      ActionPlanStepV3
    )
  )
);
