const { CREATE_WIDGET } = require("./actionTypes");

const initialPayload = {
  created: false,
};

const freshdeskReducer = (payload = initialPayload, action) => {
  if (action.type === CREATE_WIDGET) {
    return { ...payload, created: action.payload };
  }
  return payload;
};

export default freshdeskReducer;
