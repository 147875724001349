export const Colors = [
  {
    light: "#E7F5FF",
    main: "#016FB6",
    dark: "#4C88C5",
  },
  {
    light: "#E7F5FF",
    main: "#016FB6",
    dark: "#4C88C5",
  },
  {
    light: "#FFF3EE",
    main: "#EA682C",
    dark: "#FA8149",
  },
  {
    light: "#FFFBED",
    main: "#F1C312",
    dark: "#FBDA5C",
  },
  {
    light: "#EEF9DB",
    main: "#96C93E",
    dark: "#76A81F",
  },
  {
    light: "#EEF9DB",
    main: "#96C93E",
    dark: "#76A81F",
  },
];
