import React from "react";
import { Button } from "@material-ui/core";

const CogIcon = (props) => {
  const { onClick = () => { }, backgroundColor = "transparent", size = 60, fill = "#BDBDBD" } = props;

  const style = {
    minWidth: size,
    width: size,
    height: size,
    borderRadius: size / 2,
    backgroundColor,
    padding: 0,
    lineHeight: 1
  };

  return (
    <Button onClick={onClick} style={style}>
      <svg width="40" height="43" role="img" aria-label="Settings Icon" viewBox="0 0 40 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <path d="M31.8015 16.4732H31.2056C31.012 15.8566 30.7677 15.2576 30.4751 14.6821L30.8972 14.2525C31.9205 13.2121 31.8931 11.5447 30.8975 10.5324L29.6524 9.26508C28.6585 8.25207 27.02 8.22272 25.9975 9.26468L25.5751 9.6946C25.0097 9.39683 24.4212 9.14817 23.8154 8.95112L23.8154 8.34448C23.8154 6.8942 22.6562 5.71429 21.2313 5.71429L19.4697 5.71429C18.0448 5.71429 16.8856 6.8942 16.8856 8.34448L16.8856 8.95112C16.2798 9.14811 15.6913 9.39677 15.1259 9.6946L14.7039 9.26508C13.6835 8.22518 12.045 8.24956 11.0491 9.26474L9.80386 10.5321C8.80869 11.5439 8.7799 13.2114 9.80353 14.2522L10.2259 14.6821C9.9333 15.2577 9.68906 15.8566 9.49547 16.4733L8.89953 16.4733C7.47469 16.4732 6.31543 17.6531 6.31543 19.1034L6.31543 20.8966C6.31543 22.3469 7.47469 23.5268 8.89953 23.5268H9.49547C9.68906 24.1434 9.9333 24.7424 10.2259 25.3179L9.80386 25.7475C8.78056 26.788 8.80797 28.4554 9.80353 29.4676L11.0487 30.7349C12.0426 31.7479 13.6811 31.7773 14.7035 30.7353L15.1259 30.3054C15.6913 30.6032 16.2798 30.8518 16.8856 31.0489L16.8856 31.6555C16.8856 33.1058 18.0449 34.2857 19.4697 34.2857H21.2314C22.6562 34.2857 23.8155 33.1058 23.8155 31.6555L23.8155 31.0489C24.4212 30.8519 25.0098 30.6032 25.5752 30.3054L25.9972 30.7349C27.0176 31.7748 28.656 31.7505 29.652 30.7353L30.8972 29.4679C31.8924 28.456 31.9212 26.7885 30.8976 25.7477L30.4752 25.3178C30.7678 24.7423 31.012 24.1434 31.2056 23.5267H31.8016C33.2264 23.5267 34.3857 22.3468 34.3857 20.8965L34.3857 19.1033C34.3856 17.6531 33.2263 16.4732 31.8015 16.4732ZM20.3505 26.2165C16.9828 26.2165 14.2431 23.4278 14.2431 20C14.2431 16.5722 16.9828 13.7835 20.3505 13.7835C23.7182 13.7835 26.458 16.5722 26.458 20C26.458 23.4278 23.7182 26.2165 20.3505 26.2165Z" fill={fill} />
        </g>
        <defs>
          <filter id="filter0_d" x="-4" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
    </Button>
  );
};


export default CogIcon;
