import React from "react";
import { withStyles, Button, Paper, Dialog } from "@material-ui/core";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import i18n from "../../i18n/i18next";
import { TransitionDown } from "../helpers/Transition";

const UnavailablePaper = withStyles(() => ({
  root: {
    margin: 24,
    maxWidth: 768,
  },
}))(Paper);

const calculatePosition = (windowWidth) => {
  const { offsetWidth } = document.getElementById("FamilyCompanionContainer");
  return (windowWidth - offsetWidth) / 2;
};

const PageNotAvailable = props => {
  const { classes, onClose, isPreviewMode, windowHeight, windowWidth, topNavHeight = 0 } = props;

  return (
    <Dialog
      PaperComponent={UnavailablePaper}
      open={isPreviewMode}
      TransitionComponent={TransitionDown}
      classes={{
        container: classes.container
      }}
      style={{
        top: topNavHeight,
        left: calculatePosition(windowWidth),
        height: windowHeight - topNavHeight,
        maxWidth: 768
      }}
      BackdropProps={{
        classes: {
          root: classes.backdrop
        }
      }}
    >
      <div className={classes.dialogContainer}>
        <img src="/images/skipReadingProgress.png" alt="Skip" height="90" />

        <p style={{ textAlign: "center", lineHeight: "1.5em" }}>
          {i18n.t("Goal.This page will be available soon")}
        </p>

        <Button
          id="BackButton"
          className={classes.backButton}
          variant="text"
          onClick={onClose}
        >
          {`< ${i18n.t("Feedback.Back to previous page")}`}
        </Button>
      </div>
    </Dialog>
  );
};

const styles = (theme) => ({
  backdrop: {
    background: "transparent"
  },
  container: {
    background: "rgba(1, 111, 182, 0.9)",
    bottom: 0
  },
  dialogContainer: {
    padding: theme.spacing(2),
    // width: '90%',
    // height: '90vh',
    textAlign: "center",
    "& p": {
      textAlign: "left",
      fontWeight: 500,
      fontSize: 15,
      lineHeight: "18px"
    }
  },
  backButton: {
    fontSize: 12,
    fontWeight: 400,
    textDecoration: "underline",
    textTransform: "none",
  },
});

const mapStateToProps = (state) => ({
  softLogin: state.softLogin,
  windowWidth: state.windowWidth
});

export default withRouter(connect(mapStateToProps, {})(withStyles(styles)(PageNotAvailable)));
