import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import TargetIcon from "../../Icons/TargetIcon";
import TargetExceededtIcon from "../../Icons/TargetExceededtIcon";
import i18n from "../../../i18n/i18next";

const styles = () => {
  return {
    cont: {
      fontSize: 14,
      fontWeight: 700,
      padding: "0px 16px 8px",
      marginBottom: 20,
    },
    head: {
      color: "#000",
      marginBottom: 10,
      textAlign: "center",
    },
    barCont: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bar: {
      width: "87vw",
      height: 19,
      position: "relative",
      overflow: "hidden",
      background: "#eee",
      borderRadius: 20,
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.25) inset",
    },
    value: {
      flexShrink: 1,
      paddingLeft: 10,
      textAlign: "right",
    },
    target: {
      position: "relative",
      textAlign: "right",
      verticalAlign: "middle",
      lineHeight: "18px",
      height: 18,
      marginTop: 5,
    },
    congrats: {
      position: "absolute",
      color: "#008294",
      textAlign: "left",
      lineHeight: "18px",
    },
    progress: {
      height: 13,
      position: "absolute",
      borderRadius: 20,
      top: 3,
      left: 3,
      width: "0px",
      transition: "width 3s",
    },
  };
};

const RptProgressBar = (props) => {
  const { activeChild, updatedTotal, classes } = props;
  const targets = activeChild.targets;
  const tot = updatedTotal || targets.total;
  const appVersion = activeChild.appVersion;
  const exceeded = appVersion === "v3" ? tot > targets.target : false;
  const goal = exceeded ? targets.exceeded : targets.target;
  const barGoal = exceeded ? targets.exceeded - targets.target : targets.target;
  const barValue = exceeded ? tot - targets.target : tot;
  const percent = (barValue / barGoal) * 100;
  const color = exceeded ? "#EB692E" : "#008294";
  const [progressWidth, setProgressWidth] = useState(0);
  setTimeout(() => {
    setProgressWidth(percent);
  }, 10);

  return (
    <div className={classes.cont} style={{ color: color }}>
      <div className={classes.head}>
        {i18n.t("recordProgress.Progress reflects reading at home and school")}
      </div>
      <div className={classes.barCont}>
        <div className={classes.bar}>
          <div
            style={{
              width: `calc(${
                progressWidth > 100 ? 100 : progressWidth
              }% - 6px)`,
              background: color,
            }}
            className={classes.progress}
          />
        </div>
        <div className={classes.value}>{tot}</div>
      </div>
      {!exceeded && (
        <div className={classes.target}>
          {percent >= 100 && (
            <div className={classes.congrats}>
              {i18n.t("recordProgress.Congratulations")}
              <br />
              {i18n.t("recordProgress.You have reached Your target")}
            </div>
          )}
          <TargetIcon style={{ marginBottom: "-3px", marginRight: "6px" }} />
          {i18n.t("recordProgress.Target")}: {goal}
        </div>
      )}
      {exceeded && (
        <div className={classes.target}>
          <div className={classes.congrats}>
            {i18n.t("recordProgress.Congratulations")}
            <br />
            {i18n.t("recordProgress.You have exceeded Your target")}
          </div>
          <TargetExceededtIcon
            style={{ marginBottom: "-3px", marginRight: "3px" }}
          />
          {i18n.t("recordProgress.Target Exceeded")}: {goal}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(RptProgressBar);
