import { FETCH_READING_LOG_VALUES, SAVE_READING_LOG } from "./actionTypes";
import vaultApi from "../../../apis/vaultApi";

export const closeReadingLog = () => async (dispatch) => {
  dispatch({
    type: FETCH_READING_LOG_VALUES,
    payload: { entries: [], open: false },
  });
};

export const fetchReadingLog =
  (userId, authToken, apaId, apId) => async (dispatch) => {
    const response = await vaultApi.get("reading_logs/show_entries", {
      params: {
        user_id: userId,
        auth_token: authToken,
        action_plan_assignment_id: apaId,
      },
    });
    dispatch({
      type: FETCH_READING_LOG_VALUES,
      payload: { ...response.data, apaId, apId, open: true },
    });
  };

export const saveReadingLog =
  (userId, authToken, readingLogData, apaId) => async (dispatch) => {
    try {
      const response = await vaultApi.post("reading_logs/update_entries", {
        user_id: userId,
        auth_token: authToken,
        action_plan_assignment_id: apaId,
        entries: readingLogData,
      });
      dispatch({ type: SAVE_READING_LOG, payload: response });
      dispatch({
        type: FETCH_READING_LOG_VALUES,
        payload: { entries: readingLogData },
      });
      return response.data;
    } catch (err) {
      return err;
      // window.location.href = '/error';
    }
  };
