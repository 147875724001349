import i18n from "../../../i18n/i18next";
import MinutesSelect from "../Feedback/MinutesSelect";
import React from "react";
import { withStyles } from "@material-ui/core";
import { changeReminderTimeStyles } from "./styles";

const styles = (theme) => changeReminderTimeStyles(theme);

const ReadingLogSelectorMinutes = (props) => {
  const { classes, activeDay, allowAddingPoints, value, changeValue } = props;
  return (
    <div className={classes.selectsContainer}>
      <div className={classes.selectCont}>
        <div className={classes.selectText}>
          {i18n.t("recordProgress.Reading at home")}
        </div>
        <MinutesSelect
          classes={classes}
          label={i18n.t("recordProgress.Reading at home")}
          disabled={activeDay === null || !allowAddingPoints}
          onChange={changeValue}
          selectedValue={value}
          inputSize="small"
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(ReadingLogSelectorMinutes);
