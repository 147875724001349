import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import i18n from "../../../i18n/i18next";
import { sendEvent, getSessionName } from "../../helpers/GaProvider";

const styles = (theme) => ({
  timelineStep: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: theme.spacing(1),
    position: "relative",
  },
  timelineStepIcon: {
    marginRight: theme.spacing(1),
    width: 50,
    textAlign: "center",
    flexShrink: 0,
  },
  timelineStepText: {},
  newTips: {
    fontWeight: 700,
    fontSize: 20,
    color: "#000",
    "&.active": {
      color: "#000000",
    },
  },
});

class TimelineStepReward extends React.Component {
  componentDidMount() {
    const { match, softLogin } = this.props;
    const { actionPlanId, stepId, userId } = match.params;
    const sessionName = getSessionName(softLogin);
    sendEvent(
      "Feedback: Complete",
      `{apID: ${actionPlanId}, apStep: ${stepId}, userID: ${userId}, sessionName: ${sessionName}}`
    );
  }

  render() {
    const { classes, step } = this.props;
    let { text } = this.props;
    text = i18n.t("Feedback.Unlock a new tip!");

    return (
      <div className={classes.timelineStep}>
        <div className={classes.timelineStepIcon}>
          <img
            width="47"
            src="/images/icons/sports_medal.png"
            alt="reward timeline icon"
          />
        </div>
        <div className={classes.timelineStepText}>
          <Typography
            variant="body1"
            className={`${classes.newTips} ${step >= 5 ? classes.active : ""}`}
          >
            {text}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(TimelineStepReward));
