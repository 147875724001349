export const changeReminderTimeStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 154px)",
    // borderTop: "solid 4px #F5F5F5",
    // paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  weekContainer: {
    backgroundColor: "#D9FFF6",
    borderRadius: theme.spacing(2),
    margin: theme.spacing(2),
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: theme.spacing(1)
  },
  days: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(1),
    justifyContent: "space-between",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  selectsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: 160
  },
  selectCont: {
    padding: theme.spacing(2),
    paddingBottom: 0
  },
  selectText: {
    fontWeight: 700,
    fontSize: 16,
    paddingBottom: theme.spacing(1),
    textAlign: "left"
  },
  formControl: {
    width: "100%"
  },
  select: {
    display: "flex",
    width: "100%",
    border: "1px solid #3d3d3d",
    borderRadius: theme.spacing(1),
    padding: "4px 8px",
    "&::before": {
      display: "none"
    },
    "&::after": {
      display: "none"
    }
  },
  whenBody: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "17px",
    margin: theme.spacing(1)
  },
  selects: {
    display: "flex",
    marginTop: theme.spacing(1),
    justifyContent: "space-around"
  },
  weekSwitch: {
    display: "flex",
    alignItems: "center",
    color: "#333",
    paddingTop: theme.spacing(1)
  },
  weekItem: {
    flex: 1,
    display: "flex",
    justifyContent: "center"
  }
});
