import React, { useState } from "react";
import { withStyles, Grid, Typography, Drawer } from "@material-ui/core";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import StepIndicator from "../../StepIndicatior";
import { createWidget } from "../Freshdesk/actions";
import HelpIcon from "../../Icons/HelpIcon";
import i18n from "../../../i18n/i18next";
import CogIcon from "../../Icons/CogIcon";
import TopNavDrawerV3 from "../TopNavDrawerV3";
import { topNavStyles } from "../TopNavStyles";

const ActionPlanTopBar = (props) => {
  const { classes, strategyName, step, softLogin, showSteps = true } = props;
  const [drawer, setDrawer] = useState();

  const showHelpWidget = () => {
    const { softLogin, freshdesk, createWidget } = props;

    createWidget(
      { email: softLogin.parentInfo.email, name: softLogin.parentInfo.name },
      freshdesk.created
    );
  };

  const toggleDrawer = (open) => {
    setDrawer(open);
  };

  return (
    <div
      id="StrategyTopBar"
      className={classes.topBar}
      style={{ backgroundColor: "#fff", height: showSteps ? 94 : 55 }}
    >
      <Grid container className={classes.header}>
        <Grid item xs={8}>
          <Typography
            id="StrategyTitle"
            variant="h2"
            style={{ fontSize: 16, textAlign: "left", color: "#000" }}
          >
            {strategyName}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <HelpIcon
            aria-label="help"
            onClick={showHelpWidget}
            size={50}
            text={i18n.t("TopNav.FAQ")}
          />
        </Grid>
        <Grid item xs={2}>
          <CogIcon
            size={50}
            tabIndex="0"
            role="button"
            aria-label="Options"
            onClick={() => toggleDrawer(true)}
            onKeyPress={() => toggleDrawer(true)}
          />
        </Grid>
      </Grid>
      {showSteps && (
        <div className={classes.stepIndicatorContainer}>
          <StepIndicator index={1} toggled={step === 0} />
          <StepIndicator index={2} toggled={step === 1} />
          <StepIndicator index={3} toggled={step === 2} />
          <StepIndicator index={4} toggled={step === 3} />
          <StepIndicator index={5} toggled={step === 4} />
          <StepIndicator index={6} toggled={step === 5} />
        </div>
      )}
      <Drawer
        disableEscapeKeyDown
        anchor="right"
        open={drawer}
        onClose={() => setDrawer(false)}
        classes={{
          root: classes.drawerElement,
          paper: classes.paper,
        }}
      >
        <TopNavDrawerV3 handleClose={toggleDrawer} softLogin={softLogin} />
      </Drawer>
    </div>
  );
};

const styles = (theme) => topNavStyles(theme);

const mapStateToProps = (state) => ({
  softLogin: state.softLogin,
  freshdesk: state.freshdesk,
});

export default withRouter(
  connect(mapStateToProps, {
    createWidget,
  })(withStyles(styles)(ActionPlanTopBar))
);
