export const FETCH_ACTION_PLAN_DETAILS = "FETCH_ACTION_PLAN_DETAILS";
export const FETCH_ACTION_PLANS = "FETCH_ACTION_PLANS";
export const FETCH_STUDENTS = "FETCH_STUDENTS";
export const SUBMIT_STUDENT = "SUBMIT_STUDENT";
export const SUBMIT_REFLECT_FEEDBACK = "SUBMIT_REFLECT_FEEDBACK";
export const CLEAR_REFLECT_FEEDBACK = "CLEAR_REFLECT_FEEDBACK";
export const SAVE_REMINDER_TIME = "SAVE_REMINDER_TIME";
export const FETCH_FEEDBACK_REWARD = "FETCH_FEEDBACK_REWARD";
export const FETCH_TEACHER_DETAILS = "FETCH_TEACHER_DETAILS";
export const SAVE_SOFT_LOGIN = "SAVE_SOFT_LOGIN";
export const SET_HEIGHT = "SAVE_HEIGHT";
export const SET_WIDTH = "SAVE_WIDTH";
export const SUBMIT_READING_LOG = "SUBMIT_READING_LOG";
export const GET_STRATEGIES = "GET_STRATEGIES";
export const CLEAR_STRATEGIES = "CLEAR_STRATEGIES";
export const FETCH_SUBMISSIONS = "FETCH_SUBMISSIONS";
export const ADD_STUDENT = "ADD_STUDENT";
export const SET_PREVIEW = "SET_PREVIEW";
export const SET_WE_DID_IT_ACTION_PLAN = "SET_WE_DID_IT_ACTION_PLAN";
export const FETCH_STARS_PERCENTAGES = "FETCH_STARS_PERCENTAGES";
export const SET_NETWORK_ERROR = "SET_NETWORK_ERROR";
export const UNSET_NETWORK_ERROR = "UNSET_NETWORK_ERROR";
export const FETCH_ONBOARDING = "FETCH_ONBOARDING";
export const SAVE_ONBOARDING = "SAVE_ONBOARDING";
export const ZOE_HELP = "ZOE_HELP";
