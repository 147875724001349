import { FETCH_READING_LOG_VALUES } from "./actionTypes";

const initialPayload = {
  open: false,
  week: 0,
  readingLogData: []
};

const readingLogReducer = (payload = initialPayload, action) => {
  if (action.type === FETCH_READING_LOG_VALUES) {
    return {
      week: action.payload.week || payload.week,
      open: action.payload.open || payload.open,
      readingLogData: action.payload.entries,
      apaId: action.payload.apaId || payload.apaId,
      apId: action.payload.apId || payload.apId
    };
  }
  return payload;
};

export default readingLogReducer;
