import vault from "../apis/vaultApi";
import { parseChildren } from "../components/helpers/reducerHelpers";

import {
  FETCH_ACTION_PLAN_DETAILS,
  FETCH_ACTION_PLANS,
  FETCH_STUDENTS,
  SUBMIT_STUDENT,
  CLEAR_REFLECT_FEEDBACK,
  FETCH_FEEDBACK_REWARD,
  ADD_STUDENT,
  SAVE_SOFT_LOGIN,
  SET_HEIGHT,
  SUBMIT_READING_LOG,
  SUBMIT_REFLECT_FEEDBACK,
  GET_STRATEGIES,
  CLEAR_STRATEGIES,
  SET_PREVIEW,
  SET_WE_DID_IT_ACTION_PLAN,
  FETCH_STARS_PERCENTAGES,
  SET_WIDTH,
  SAVE_ONBOARDING,
  ZOE_HELP,
} from "../actionTypes";
import { FETCH_CHANGE_REMINDER_VALUES } from "../components/FamilyCompanion/ChangeReminderTime/actionTypes";

export const saveOnboardingStatus = (userId, authToken, onboarding) => async (dispatch) => {
  dispatch({ type: ZOE_HELP, payload: onboarding });
  vault
    .post(`/students/${userId}/onboarding`, {
      auth_token: authToken,
      onboarding,
    })
    .then((response) =>
      dispatch({ type: SAVE_ONBOARDING, payload: response.data }));
};

export const fetchActionPlanDetail = (actionPlanId, userId, authToken) => async (dispatch) => {
  vault
    .get(`/action_plans/${actionPlanId}`, {
      params: { user_id: userId, auth_token: authToken },
    })
    .then((response) =>
      dispatch({ type: FETCH_ACTION_PLAN_DETAILS, payload: response.data }));
};

export const setWeDidIt = (step, id) => async (dispatch) => {
  dispatch({ type: SET_WE_DID_IT_ACTION_PLAN, payload: { step, id } });
};

export const fetchActionPlans = () => async (dispatch) => {
  vault
    .get("/action_plans")
    .then((response) =>
      dispatch({ type: FETCH_ACTION_PLANS, payload: response.data }));
};

export const fetchStudents = () => async (dispatch) => {
  vault
    .get("/students")
    .then((response) =>
      dispatch({ type: FETCH_STUDENTS, payload: response.data }));
};

export const createStudentWithPlan = (actionPlanId, firstName, lastName, phoneNumber, language) =>
  async (dispatch) => {
    vault
      .post("/students", {
        action_plan_id: actionPlanId,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        language,
      })
      .then((response) =>
        dispatch({ type: SUBMIT_STUDENT, payload: response.data }));
  };

export const submitReflectFeedback =
  (
    actionPlanStep,
    progress,
    tipHelpfulness,
    actionPlanId,
    userId,
    authToken,
    apaId
  ) =>
  async (dispatch) => {
    vault
      .post("/reflect_feedbacks", {
        user_id: userId,
        action_plan_id: actionPlanId,
        apa_id: apaId,
        step: actionPlanStep,
        progress,
        tip_helpfulness: tipHelpfulness,
        auth_token: authToken,
      })
      .then((response) =>
        dispatch({ type: SUBMIT_REFLECT_FEEDBACK, payload: response.data }));
  };

export const updateReflectFeedback = (reflectFeedbackId, progress, tipHelpfulness) => async (dispatch) => {
  vault
    .put(`/reflect_feedbacks/${reflectFeedbackId}`, {
      progress,
      tip_helpfulness: tipHelpfulness,
    })
    .then((response) =>
      dispatch({ type: SUBMIT_REFLECT_FEEDBACK, payload: response.data }));
};

export const clearReflectFeedback = () => async (dispatch) => {
  dispatch({ type: CLEAR_REFLECT_FEEDBACK, payload: {} });
};

export const setHeight = (windowHeight) => async (dispatch) => {
  dispatch({ type: SET_HEIGHT, payload: windowHeight });
};

export const setWidth = (windowWidth) => async (dispatch) => {
  dispatch({ type: SET_WIDTH, payload: windowWidth });
};

export const saveSoftLogin = (childId = null) =>
  async (dispatch) => {
    vault.get("/parent").then((response) => {
      const softLogin = parseChildren(response.data, childId);

      dispatch({ type: SAVE_SOFT_LOGIN, payload: softLogin });
      dispatch({ type: ZOE_HELP, payload: softLogin.activeChild.onboarding });
      dispatch({
        type: FETCH_CHANGE_REMINDER_VALUES,
        payload: {
          reminderDay: softLogin.activeChild.reminderDay,
          reminderTime: softLogin.activeChild.reminderTime,
          reminderFrequency: softLogin.activeChild.reminderFrequency,
        },
      });
    });
  };

export const clickPreview = () => async (dispatch) => {
  dispatch({ type: SET_PREVIEW, payload: true });
};

export const getStrategies = (userId, authToken) => async (dispatch) => {
  vault
    .get(`/student_strategies?user_id=${userId}&auth_token=${authToken}`)
    .then((response) => dispatch({ type: GET_STRATEGIES, payload: response }));
};

export const changeZoeHelp = (obj) => async (dispatch) => {
  dispatch({ type: ZOE_HELP, payload: obj });
};

export const clearStrategies = () => async (dispatch) => {
  dispatch({ type: CLEAR_STRATEGIES, payload: {} });
};

export const sendReadingLog = (
  userId,
  authToken,
  actionPlanId,
  step,
  progress,
  daysRead,
  minutesRead,
  emoji,
  comment,
  program,
  apaId
) =>
  async (dispatch) =>
    vault
      .post("/reading_logs", {
        user_id: userId,
        auth_token: authToken,
        action_plan_id: actionPlanId,
        step,
        progress,
        days_read: daysRead,
        minutes_read: minutesRead,
        emoji,
        comment,
        program,
        apa_id: apaId,
      })
      .then((response) =>
        dispatch({ type: SUBMIT_READING_LOG, payload: response.data }));

export const fetchStarsPercentages = (id, authToken, apaId) => async (dispatch) => {
  vault
    .get("/percentage", {
      params: {
        user_id: id,
        auth_token: authToken,
        action_plan_assignment_id: apaId,
      },
    })
    .then((response) =>
      dispatch({
        type: FETCH_STARS_PERCENTAGES,
        payload: response.data,
        apaId,
      }));
};
