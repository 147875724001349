/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Button,
  // MenuList,
  Menu,
  MenuItem,
  Icon,
  IconButton,
} from "@material-ui/core";
import {
  Close,
  AddCircleOutline,
  Schedule,
  ArrowDropDown,
  RemoveCircleOutline,
  Language,
  Edit,
} from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import { isEmpty, isNil } from "ramda";
import { Colors } from "../themes/Colors";
import Loading from "../Loading";

import i18n from "../../i18n/i18next";

import ChangeLanguage from "./ChangeLanguage/ChangeLanguage";
import { openChangeReminder } from "./ChangeReminderTime/actions";
import config from "../../config";
import vaultApi from "../../apis/vaultApi";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

// const menuItemStyle = {
//   paddingTop: 6,
//   paddingBottom: 6,
//   marginLeft: 25,
// };

const StyledMenu = withStyles({
  paper: {
    background: "#2885c2",
    width: "80%",
    left: "auto !important",
    right: 0,
    marginTop: "50px",
    height: "100%",
  },
})(Menu);

const StyledMenuItem = withStyles({
  root: {
    color: "#ffffff",
    borderBottom: "1px solid #fff",
    padding: 16,
    "&:hover": {
      background: "#fff",
      color: "#333",
      "& span": {
        color: "#333",
      },
    },
  },
})(MenuItem);

class TopNavDrawerV3 extends Component {
  constructor(props) {
    super(props);
    this.changeLanguageRef = React.createRef();
    this.state = {
      anchorEl: null,
      langOpen: false,
      browseStrategies: false,
    };
  }

  changeLanguage = () => {};

  handleSwitchChildMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleSwitchChildMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChangeLanguageOpen = () => {
    this.setState((prevState) => ({ langOpen: !prevState.langOpen }));
    this.changeLanguageRef.current.open();
  };

  handleChangeReminderOpen = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.openChangeReminder(true);
  };

  checkInternetConnection = () =>
    vaultApi.get("/healthcheck", { baseURL: config.apiUrl.slice(0, -3) });

  addChild = (e) => {
    const { history } = this.props;
    e.stopPropagation();
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();

    this.checkInternetConnection().then(() => {
      history.push("/onboarding/add-child");
    });
  };

  removeChild = (e) => {
    const { history } = this.props;
    e.stopPropagation();
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();

    this.checkInternetConnection().then(() => {
      history.push("/onboarding/remove-child");
    });
  };

  gotoLink = (link) => {
    window.location.href = link;
  };

  render() {
    const { classes, softLogin, handleClose, changeReminderTime } = this.props;
    const { anchorEl } = this.state;
    const { language } = softLogin.activeChild;

    if (isEmpty(softLogin) || isNil(changeReminderTime.reminderTime)) {
      return <Loading />;
    }
    return (
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <Icon className={classes.closeIcon}>
            <Close
              role="button"
              aria-label="close"
              tabIndex="0"
              onKeyPress={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  handleClose(false);
                }
              }}
              onClick={() => handleClose(false)}
            />
          </Icon>
          <div className={classes.childContainer}>
            <span className={classes.avatar}>
              {/* <img
                  src={`https://robohash.org/${softLogin.activeChild.authToken}.png?set=set4`}
                  height={30}
                  alt={`Avatar of ${softLogin.activeChild.firstName}`}
                /> */}
            </span>
            <span className={classes.name}>
              {softLogin.activeChild.firstName}
            </span>
          </div>
          {!isEmpty(softLogin.otherChildren) && (
            <div className={classes.selectChild}>
              <Button
                onClick={this.handleSwitchChildMenuOpen}
                variant="text"
                size="small"
                aria-label="switch child"
                className={classes.switchChildButton}
                id="SwitchChildButton"
              >
                {i18n.t("TopNav.Switch child")}
                <ArrowDropDown
                  style={{ marginLeft: "10px", color: "#ffffff" }}
                />
              </Button>
              <StyledMenu
                variant="menu"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                disablePortal
                onClose={this.handleSwitchChildMenuClose}
                PopoverClasses={{
                  root: classes.childMenuPopover,
                }}
              >
                {softLogin.otherChildren.map((child, i) => (
                  <StyledMenuItem
                    onClick={() => this.gotoLink(child.goalLink)}
                    key={child.id}
                    value={10 + i}
                  >
                    <span className={classes.childLink}>{child.firstName}</span>
                  </StyledMenuItem>
                ))}
                <div style={{ height: 40 }} />
              </StyledMenu>
            </div>
          )}
        </div>
        <div className={classes.reminderContainer}>
          <div className={classes.sectionHeader} id="#remindersHeader">
            {i18n.t("TopNav.Reminders")}
          </div>
          <div className={classes.sectionContent}>
            <div
              className={classes.sectionContentItem}
              style={{ marginTop: "16px" }}
            >
              <span className={classes.contentIcon}>
                <Schedule />
              </span>
              <span
                id="ReminderTimeDisplay"
                className={classes.contentText}
                style={{ fontWeight: 500, color: "#016FB6" }}
              >
                {changeReminderTime.reminderFrequency === "weekly"
                  ? i18n.t("TopNav.Every week")
                  : i18n.t("TopNav.Every Day")}
                <br />
                {changeReminderTime.reminderFrequency === "weekly" &&
                  i18n.t(
                    `Feedback.${days[changeReminderTime.reminderDay]}`
                  )}{" "}
                <Moment parse="h:mm A" format="h:mm A">
                  {changeReminderTime.reminderTime.toString()}
                </Moment>
              </span>
            </div>
            <ChangeLanguage innerRef={this.changeLanguageRef} />
          </div>
        </div>
        <div className={classes.linksContainer} style={{ flexGrow: 1 }}>
          <div className={classes.sectionHeader}>
            {i18n.t("Feedback.Settings")}
          </div>

          <div className={classes.sectionContent}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className={classes.contentIcon}>
                <Language />
              </span>
              <span className={classes.contentText} style={{ color: "#000" }}>
                {language === "en"
                  ? i18n.t("Feedback.English")
                  : i18n.t("Feedback.Spanish")}
              </span>
              <IconButton
                aria-label="edit"
                onClick={this.handleChangeLanguageOpen}
                style={{ color: "#006FB6" }}
              >
                <Edit />
              </IconButton>
            </div>
          </div>
          <div className={classes.sectionContent} style={{ display: "none" }}>
            <div className={classes.sectionContentItem}>
              <span className={classes.contentIcon}>
                <AddCircleOutline />
              </span>
              <span>
                <a
                  href="#"
                  className={classes.contentText}
                  style={{ textDecoration: "none", color: "#000" }}
                  onClick={this.addChild}
                  onKeyDown={this.addChild}
                  role="button"
                  tabIndex={0}
                  id="AddChildLink"
                >
                  {i18n.t("TopNav.Add Child")}
                </a>
              </span>
            </div>
            <div className={classes.sectionContentItem}>
              <span className={classes.contentIcon}>
                <RemoveCircleOutline />
              </span>
              <span>
                <a
                  href="#"
                  className={classes.contentText}
                  style={{ textDecoration: "none", color: "#000" }}
                  onClick={this.removeChild}
                  onKeyDown={this.removeChild}
                  role="button"
                  tabIndex={0}
                  id="RemoveChildLink"
                >
                  {softLogin.otherChildren.length === 0
                    ? i18n.t("Onboarding.Delete account")
                    : i18n.t("TopNav.Remove child")}
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className={classes.footerContainer} style={{ display: "flex" }}>
          <div
            className={classes.sectionContent}
            style={{ alignSelf: "flex-end" }}
          >
            <Button
              variant="outlined"
              className={classes.orangeButton}
              onClick={() => {
                window.location.href = "https://www.surveymonkey.com/r/JTRCQTR";
              }}
            >
              {i18n.t("TopNav.Give Feedback")}
            </Button>
            <Button
              variant="outlined"
              className={classes.blueButton}
              onClick={() => {
                window.location.href = "https://bit.ly/2QQWUz3";
              }}
            >
              {i18n.t("TopNav.FAQ")}
            </Button>
            {/* <Button
                variant="outlined"
                className={classes.greyButton}
                fullWidth
                onClick={() => {
                  window.location.href =
                    "https://www.surveymonkey.com/r/JTRCQTR";
                }}
              >
                {i18n.t("TopNav.Log Out")}
              </Button> */}
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    minHeight: "100%",
    position: "absolute",
    background: "#fff",
    right: 0,
  },
  childMenuPopover: {
    maxWidth: 768,
    margin: "0px auto",
  },
  headerContainer: {
    background: Colors[0].main,
    padding: theme.spacing(2),
  },
  childContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  switchChildButton: {
    color: "#ffffff",
    textTransform: "none",
    fontSize: 14,
    width: "95%",
    margin: 10,
    background: "#2EC0FF",
    borderRadius: 20,
  },
  avatar: {
    background: "#ffffff",
    borderRadius: 35,
    padding: 5,
    marginRight: 10,
    width: 35,
    height: "35px",
    lineHeight: "35px",
    verticalAlign: "middle",
    textAlign: "center",
    "& img": {
      lineHeight: "35px",
      verticalAlign: "middle",
      textAlign: "center",
    },
  },
  name: {
    flexGrow: 1,
    fontSize: 15,
    fontWeight: 600,
    color: "#ffffff",
  },

  reminderContainer: {},
  sectionHeader: {
    background: Colors[0].light,
    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    color: "#000000",
    fontSize: 14,
    fontWeight: 700,
  },
  sectionContent: {
    padding: `0 ${theme.spacing(2)}px 0`,
  },
  sectionContentItem: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "flex-start",
    alignItems: "stretch",
    paddingBottom: 16,
  },
  contentIcon: {
    flexShrink: 1,
    minWidth: `${theme.spacing(5)}px`,
    color: "#016FB6",
    "& Icon": {},
  },
  contentText: {
    flexGrow: 1,
    fontSize: 16,
    fontWeight: "400",
  },
  footerContainer: {
    // background: Colors[0].light,
    paddingBottom: 20,
  },
  orangeButton: {
    color: "#707070",
    background: "#F5F5F5",
    boxShadow: "0px 6px 0px #D4D4D4",
    borderRadius: 50,
    borderWidth: 0,
    marginTop: 10,
    fontWeight: 900,
  },
  blueButton: {
    color: "#707070",
    background: "#F5F5F5",
    boxShadow: "0px 6px 0px #D4D4D4",
    borderRadius: 50,
    marginTop: 10,
    borderWidth: 0,
    marginLeft: 10,
    width: 100,
    fontWeight: 900,
  },
  greyButton: {
    color: "#9B9B9B",
    borderColor: "#9B9B9B",
    borderRadius: 10,
    borderWidth: 2,
  },
  closeIcon: {
    color: "#ffffff",
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
    width: "100%",
    overflow: "inherit",
  },
  childLink: {
    color: "#ffffff",
    fontSize: 14,
    fontWeight: 500,
    textDecoration: "none",
  },
  footerTextButton: {
    color: "#000",
    fontSize: 16,
    fontWeight: 500,
    textDecoration: "none",
    textTransform: "none",
    paddingTop: 10,
    paddingBottom: 10,
  },
  buttonStyle: {
    // minWidth: size,
    width: "100%",
    // height: size,
    // borderRadius: size / 2,
    backgroundColor: "#eee",
    padding: 0,
    lineHeight: 1,
    margin: 8,
    fontSize: 22,
    textTransform: "initial",
    fontWeight: 700,
    boxShadow: "0px 5px 0px #1CAFEE",
  },
});

const mapStateToProps = (state) => ({
  softLogin: state.softLogin,
  changeReminderTime: state.changeReminderTime,
});

export default withRouter(
  connect(mapStateToProps, { openChangeReminder })(
    withStyles(styles, Colors)(TopNavDrawerV3)
  )
);
