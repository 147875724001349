import React from "react";
import { connect } from "react-redux";
import { withStyles, Button } from "@material-ui/core";

import { isEmpty } from "ramda";

import { headerHeightFeedback, footerHeight } from "./heights";

import i18n from "../../../i18n/i18next";

const styles = (theme) => ({
  root: {
    display: "flex",
  },

  padded: {
    padding: theme.spacing(3),
  },
  feedbackStepImage: {
    backgroundColor: "#F6FAFD",
    textAlign: "center",
    padding: theme.spacing(2),
  },
  feedbackContent: {
    overflow: "scroll",
    height: "auto",
  },
  backButtonContainer: {
    height: 70,
    width: `calc(100vw - ${theme.spacing(4)}px)`,
    backgroundColor: "#ffffff",
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      1
    )}px`,
    textAlign: "center",
  },
  backButton: {
    fontSize: 16,
    fontWeight: 400,
    textDecoration: "underline",
    textTransform: "none",
  },
  thumb: {
    width: "50%",
    padding: "20px 0",
    "-webkit-appearence": "none",
    appearence: "none",
    background: "transparent",
    border: "0",
  },
  thumbSelected: {
    width: "50%",
    padding: "20px 0",
    "-webkit-appearence": "none",
    appearence: "none",
    background: "#BAD2E1",
    border: "0",
  },
});

class FeedbackThumbs extends React.Component {
  constructor(props) {
    super(props);
    this.sendFeedback = this.sendFeedback.bind(this);
    this.state = {
      value: "",
      buttonTxt: i18n.t("Feedback.Select"),
      disabled: true,
    };
  }


  componentDidUpdate() {
    this.checkIfSHouldGoNext();
  }

  checkIfSHouldGoNext = () => {
    const { next } = this.props;
    if (this.shouldGoNext()) {
      this.setState(
        { disabled: false, buttonTxt: i18n.t("Feedback.Sent") },
        () => {
          next();
        }
      );
    }
  }

  handleThumbClick = (ev) => {
    this.setState({
      value: ev,
      buttonTxt: i18n.t("Feedback.Select"),
      disabled: false,
    });
  };

  shouldGoNext() {
    const { reflectFeedback } = this.props;
    const { disabled } = this.state;

    return (
      disabled
      && !isEmpty(reflectFeedback)
      && !isEmpty(reflectFeedback.tipHelpfulness)
    );
  }

  sendFeedback() {
    const { handleSendFeedbackButton, id } = this.props;
    const { value } = this.state;
    this.setState({ disabled: true, buttonTxt: i18n.t("Feedback.Sending") });
    handleSendFeedbackButton(
      { selected: value },
      id
    );
  }

  // handleThumbClick = val => {
  //   this.setState({ value: val }, () => {
  //     this.sendFeedback();
  //   });
  //   console.log('asdasdasd');
  // };

  render() {
    const { classes, windowHeight, stepBack } = this.props;
    const { disabled, buttonTxt } = this.state;

    const feedbackHeight = windowHeight - headerHeightFeedback - footerHeight;
    return (
      <div
        className={classes.feedbackSlide}
        style={{
          height: `${windowHeight - headerHeightFeedback}px`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className={classes.feedbackContent}
          style={{ height: `${feedbackHeight}px` }}
        >
          <div className={classes.feedbackStepImage}>
            {/* <img src={`/images/feedback_image_${id}.png`} height="70" alt="feedback step" /> */}
          </div>
          {/* <div className={classes.padded}>
            <Typography variant="body1" paragraph>
              {i18n.t("Feedback.Was this tip helpful?")}
            </Typography>
            <button
              id="ThumbUp"
              type="button"
              className={value === "up" ? classes.thumbSelected : classes.thumb}
              onClick={() => this.handleThumbClick("up")}
            >
              <img src="/images/thumbUp.png" alt="Thumb Up" />
            </button>
            <button
              id="ThumbDown"
              type="button"
              className={
                value === "down" ? classes.thumbSelected : classes.thumb
              }
              onClick={() => this.handleThumbClick("down")}
            >
              <img src="/images/thumbDown.png" alt="Thumb Down" />
            </button>
            <Tip
              tip={i18n.t(
                "Feedback.This feedback will help us improve our reading tip content"
              )}
            />
          </div> */}
        </div>
        <div className={classes.backButtonContainer}>
          <Button
            id="SelectButton"
            disabled={disabled}
            onClick={this.sendFeedback}
            fullWidth
            color="secondary"
            variant="contained"
          >
            {buttonTxt}
          </Button>
          <Button
            id="BackButton"
            className={classes.backButton}
            variant="text"
            onClick={stepBack}
          >
            {`< ${i18n.t("Feedback.Back to previous page")}`}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  thisFeedback: state.feedback,
  reflectFeedback: state.reflectFeedback,
  windowHeight: state.windowHeight,
});

export default connect(mapStateToProps)(
  withStyles(styles)(FeedbackThumbs)
);
