import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import { isNil } from "ramda";
import parse from "html-react-parser";

import config from "../../../config";
import Tip from "../../Tip";

const styles = (theme) => ({
  tipContainer: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  step: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  stepImage: {
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: "#ffffff",
  },
  typo: {
    fontWeight: 700,
    marginLeft: 10,
    background: "#3dff00",
  },
  stepBody: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "28px",
  },
});

const ActionPlanCard = (props) => {
  const { classes, stepContent, showIntro = true } = props;

  return (
    <div>
      <div className={classes.stepImage}>
        <img
          width="100%"
          src={`${config.imgRoot}${stepContent.img}`}
          alt={stepContent.heading}
        />
      </div>
      <div className={classes.step}>
        <div className={classes.stepTitle}>
          <Typography id="StrategyHeading" gutterBottom variant="subtitle1">
            {stepContent.heading}
          </Typography>
        </div>
        <div
          style={{
            padding: 16,
            background: "#F6F6F6",
            boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.15)",
            borderRadius: "6px",
            marginBottom: 20,
          }}
        >
          {showIntro && (
            <div
              id="StrategyIntro"
              style={{ paddingBottom: 16, fontWeight: 500 }}
            >
              {parse(stepContent.intro)}
            </div>
          )}
          {stepContent.body.map((paragraph) => (
            <Typography
              id="StretegyBody"
              className={classes.stepBody}
              key={paragraph.length}
              component="span"
              variant="body1"
            >
              {parse(paragraph)}
            </Typography>
          ))}
        </div>
      </div>
      {!isNil(stepContent.tip) && (
        <div className={classes.tipContainer}>
          <Tip tip={stepContent.tip} />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ActionPlanCard);
