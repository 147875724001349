import { SET_STARS_INCREASE_DATA, OPEN_STARS_INCREASE, CLOSE_STARS_INCREASE } from "./actionTypes";

const initialPayload = {
  message: "",
  image: "",
  isAtMax: false,
  stars: 0,
  totalStars: 0,
  open: false,
  link: ""
};

const starsIncreaseReducer = (payload = initialPayload, action) => {
  if (action.type === SET_STARS_INCREASE_DATA) {
    return {
      ...payload,
      message: action.payload.message || payload.message,
      image: action.payload.image || payload.image,
      isAtMax: action.payload.max || payload.isAtMax,
      stars: action.payload.stars || payload.stars,
      totalStars: action.payload.totalStars || payload.totalStars,
      link: action.payload.link || payload.link
    };
  }
  if (action.type === OPEN_STARS_INCREASE) {
    return {
      ...payload,
      open: true
    };
  }
  if (action.type === CLOSE_STARS_INCREASE) {
    return initialPayload;
  }
  return payload;
};

export default starsIncreaseReducer;
