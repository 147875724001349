import { GET_STRATEGIES, CLEAR_STRATEGIES } from "../actionTypes";

const StrategiesReducer = (strategies = null, action) => {
  if (strategies == null) {
    return {};
  } if (action.type === GET_STRATEGIES) {
    return action.payload.data;
  } if (action.type === CLEAR_STRATEGIES) {
    return {};
  }
  return strategies;
};

export default StrategiesReducer;
