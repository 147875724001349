import axios from "axios";
import config from "../config";
import { SET_NETWORK_ERROR, UNSET_NETWORK_ERROR } from "../actionTypes";
import { FCstore } from "../store/FCstore";

const api = axios.create({
  baseURL: config.apiUrl,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    const { networkError } = FCstore.getState();
    if (networkError.open) {
      FCstore.dispatch({ type: UNSET_NETWORK_ERROR });
    }
    return response;
  },
  (error) => {
    if (!error.response && error.request) {
      return new Promise((resolve) => {
        FCstore.dispatch({
          type: SET_NETWORK_ERROR,
          payload: {
            url: error.config.url,
            func: () => resolve(api(error.config)),
          },
        });
      });
    }
    return Promise.reject(error);
  }
);

export default api;
