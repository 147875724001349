import { combineReducers } from "redux";
import ActionPlanReducer from "./actionPlanReducer";
import SaveSoftLoginReducer from "./softLoginReducer";
import WindowHeightReducer from "./windowHeightReducer";
import ReflectFeedbackReducer from "./reflectFeedbackReducer";
import StrategiesReducer from "./strategiesReducer";
import changeReminderReducer from "../components/FamilyCompanion/ChangeReminderTime/reducer";
import changeReminderTypeReducer from "../components/FamilyCompanion/ChangeReminderType/reducer";
import readingLogReducer from "../components/FamilyCompanion/ReadingLogV5/reducer";
import starsIncreaseReducer from "../components/FamilyCompanion/StarsIncrease/reducer";
import PercentagesReducer from "./percentagesReducer";
import ZoeHelpReducer from "./zoeHelpReducer";
import NetworkErrorReducer from "./networkErrorReducer";
import WindowWidthReducer from "./windowWidthRecuder";
import freshdeskReducer from "../components/FamilyCompanion/Freshdesk/reducer";

const rootReducer = combineReducers({
  actionPlanDetail: ActionPlanReducer, // FC, TC
  softLogin: SaveSoftLoginReducer, // FC, O
  windowHeight: WindowHeightReducer, // FC, O
  windowWidth: WindowWidthReducer,
  reflectFeedback: ReflectFeedbackReducer, // FC
  strategies: StrategiesReducer, // FC, O
  changeReminderTime: changeReminderReducer, // FC
  changeReminderType: changeReminderTypeReducer, //FC
  readingLog: readingLogReducer,
  starsIncrease: starsIncreaseReducer,
  percentage: PercentagesReducer,
  networkError: NetworkErrorReducer,
  zoeHelp: ZoeHelpReducer,
  freshdesk: freshdeskReducer,
});

export default rootReducer;
