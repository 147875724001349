import { ZOE_HELP } from "../actionTypes";

const zoeHelpReducer = (zoeHelp = null, action) => {
  if (zoeHelp == null) {
    return {};
  } if (action.type === ZOE_HELP) {
    return action.payload || {};
  }
  return zoeHelp;
};

export default zoeHelpReducer;
