// import { createStore, applyMiddleware } from "redux";
// import thunk from "redux-thunk";

// import reducers from "./reducers";

// export const store = createStore(reducers, applyMiddleware(thunk));

import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers/_FCreducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const FCstore = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
