import { withStyles, InputBase } from "@material-ui/core";

export const CustomInput = withStyles((theme) => ({
  root: {
    margin: "0 auto",
    paddingRight: "-14px",
    "label + &": { marginTop: theme.spacing(3) },
  },
  input: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#FFF",
    border: "4px solid #FFF",
    webkitAppearence: "none",
    fontSize: 16,
    fontWeight: 700,
    padding: "10px 26px 10px 12px",
    fontFamily: "Gotham A",
    width: "100%",
    "&:focus": {
      border: "4px solid #FFF",
      backgroundColor: "#FFF",
      borderRadius: 10,
    },
  },
}))(InputBase);
