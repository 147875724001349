import {
  OPEN_CHANGE_REMINDER,
  FETCH_CHANGE_REMINDER_VALUES,
  SKIP_CHANGE_REMINDER,
} from "./actionTypes";

const initialPayload = {
  open: false,
  reminderDay: null,
  reminderTime: null,
  reminderFrequency: null,
  skip: false,
};

const changeReminderReducer = (payload = initialPayload, action) => {
  if (action.type === OPEN_CHANGE_REMINDER) {
    return { ...payload, open: action.payload };
  }
  if (action.type === FETCH_CHANGE_REMINDER_VALUES) {
    return {
      ...payload,
      reminderDay: action.payload.reminderDay,
      reminderTime: action.payload.reminderTime,
      reminderFrequency: action.payload.reminderFrequency,
    };
  }
  if (action.type === SKIP_CHANGE_REMINDER) {
    return {
      ...payload,
      skip: true,
    };
  }
  return payload;
};

export default changeReminderReducer;
