/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable indent */
import React, { Component } from "react";
import { withStyles, Link, Button, Dialog, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, isNil } from "ramda";
import { sendReadingLog, saveSoftLogin } from "../../../actions";
import SkipPopup from "./SkipPopup";
import i18n from "../../../i18n/i18next";
import { sendEvent, getSessionName } from "../../helpers/GaProvider";
import { TransitionDown } from "../../helpers/Transition";

const whOffset = 180;

const CustomPaper = withStyles(() => ({
  root: {
    margin: 24,
    maxWidth: 768,
  },
}))(Paper);

const fullPaper = withStyles(() => ({
  root: {
    maxHeight: "100vh",
    maxWidth: 768,
  },
}))(Paper);

class RecordProgressV3 extends Component {
  state = {
    stepId: 0,
    days: 0,
    minutes: 0,
    emoji: "",
    feedback: [],
    button: i18n.t("recordProgress.Done"),
    wantToSkip: false,
    progress: "",
  };

  componentDidMount() {
    window.addEventListener("keydown", this.close);
  }

  componentDidUpdate() {
    this.slideScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.close);
  }

  handleInputChange = (key, value) => {
    this.setState({ [key]: value });
    this.handleNextStep();
  };

  handleSkipBtn = () => {
    this.setState((prevState) => ({ wantToSkip: !prevState.wantToSkip }));
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      this.handleSkipBtn();
    }
  };

  handleEmojiKeyDown = (e, a, b) => {
    if (e.key === "Enter" || e.key === " ") {
      this.handleInputChange(a, b);
    }
  };

  close = (e) => {
    if (e.key === "Escape" && this.state.wantToSkip === true) {
      this.handleSkipBtn();
    }
  };

  handleFeedbackChange = (fb) => {
    const { feedback } = this.state;

    if (feedback.indexOf(fb) === -1) {
      feedback.push(fb);
    } else {
      feedback.splice(feedback.indexOf(fb), 1);
    }
    this.setState({ feedback });
  };

  handleProgressChange = (fb) => {
    this.setState({ progress: fb });
    this.handleNextStep();
  };

  handleNextStep = () => {
    const { stepId } = this.state;
    if (stepId <= 1) {
      setTimeout(() => {
        this.setState({ stepId: stepId + 1 }, () => {
          this.slideScroll();
        });
      }, 500);
    }
  };

  handlePrevStep = () => {
    const { stepId } = this.state;
    if (stepId > 0) {
      setTimeout(() => {
        this.setState({ stepId: stepId - 1 }, () => {
          this.slideScroll();
        });
      }, 500);
    }
  };

  slideScroll = () => {
    const { stepId } = this.state;
    const slideHeight = document.getElementById("slideContainer").clientHeight;
    document.getElementById("slides").scrollTop = stepId * slideHeight;
  };

  handleSendFeedbackButton = () => {
    const { match, closeRecordProgress, softLogin } = this.props;
    const { actionPlanId, stepId, userId } = match.params;
    const sessionName = getSessionName(softLogin);

    this.setState({ button: i18n.t("recordProgress.Sending") });
    this.sendReadingLog().then(() => {
      sendEvent(
        "Feedback: Sent",
        `{apID: ${actionPlanId}, apStep: ${stepId}, userID: ${userId}, sessionName: ${sessionName}}`
      );
      setTimeout(() => {
        closeRecordProgress();
      }, 500);
    });
  };

  handleResetProgress = () => {
    this.setState(
      { stepId: 0, days: 0, minutes: 0, emoji: "", feedback: [], progress: "" },
      () => {
        document.getElementById("slides").scrollTop = 0;
      }
    );
  };

  sendReadingLog = () => {
    const { days, minutes, emoji, feedback, progress } = this.state;
    const {
      hideRecordProgress,
      softLogin,
      sendReadingLog,
      saveSoftLogin,
      actionPlanId,
      stepId,
    } = this.props;
    const { id, authToken, questionnaire, currentApa } = softLogin.activeChild;
    const { code } = questionnaire || {};
    const { apaId } = currentApa;

    return sendReadingLog(
      id,
      authToken,
      actionPlanId,
      stepId,
      progress,
      days,
      minutes,
      emoji,
      feedback.join("; "),
      code || "v3",
      apaId
    ).then(() => {
      saveSoftLogin(id);
      setTimeout(() => hideRecordProgress(), 500);
    });
  };

  handleQuitButton = () => {
    const { closeRecordProgress, skipRP } = this.props;
    const { stepId } = this.state;
    closeRecordProgress(stepId, true);
    skipRP();
  };

  isCompleted = () => {
    const { stepId, feedback } = this.state;
    return stepId > 1 && feedback.length !== 0;
  };

  stepHasAnswer = () => {
    const { stepId, progress, feedback, emoji } = this.state;
    return (
      (stepId === 0 && !isEmpty(progress)) ||
      // || (stepId === 1 && !isEmpty(days))
      // || (stepId === 2 && !isEmpty(minutes))
      (stepId === 1 && !isEmpty(emoji)) ||
      (stepId === 2 && !isEmpty(feedback))
    );
  };

  render() {
    const { stepId, emoji, feedback, button, wantToSkip, progress } =
      this.state;
    const { classes, softLogin, actionPlanDetail, windowHeight } = this.props;

    const goodFB = {
      text: i18n.t("recordProgress.Glad to hear that What went well"),
      answers: [
        i18n.t("recordProgress.Helpful tip"),
        i18n.t("recordProgress.Fun"),
        i18n.t("recordProgress.My childs reading improved"),
        i18n.t("recordProgress.Felt more connected to my child"),
        i18n.t("recordProgress.Other"),
      ],
    };
    const neutralFB = {
      text: i18n.t("recordProgress.Sorry to hear that what could be better"),
      answers: [
        i18n.t("recordProgress.More helpful tip"),
        i18n.t("recordProgress.More level appropriate"),
        i18n.t("recordProgress.Less distractions"),
        i18n.t("recordProgress.More reading time"),
        i18n.t("recordProgress.Other"),
      ],
    };
    const badFB = {
      text: i18n.t("recordProgress.Sorry to hear that what could be better"),
      answers: [
        i18n.t("recordProgress.More helpful tip"),
        i18n.t("recordProgress.More level appropriate"),
        i18n.t("recordProgress.Less distractions"),
        i18n.t("recordProgress.More reading time"),
        i18n.t("recordProgress.Other"),
      ],
    };

    let fb = goodFB;

    switch (emoji) {
      case "5":
        fb = goodFB;
        break;
      case "4":
        fb = goodFB;
        break;
      case "3":
        fb = neutralFB;
        break;
      case "2":
        fb = neutralFB;
        break;
      case "1":
        fb = badFB;
        break;
      default:
        fb = neutralFB;
        break;
    }
    if (
      isEmpty(softLogin) ||
      isNil(softLogin) ||
      isEmpty(actionPlanDetail) ||
      isNil(actionPlanDetail)
    ) {
      return <div>loading</div>;
    }
    return (
      <Dialog open fullScreen disableEscapeKeyDown PaperComponent={fullPaper}>
        <div className={classes.header}>
          <span className={classes.title}>
            {i18n.t("recordProgress.Record progress")}
          </span>
          <Link
            id="SkipButton"
            onClick={() => this.handleSkipBtn()}
            onKeyDown={this.handleKeyDown}
            className={classes.skipBtn}
            role="button"
            tabIndex={0}
          >
            {i18n.t("recordProgress.skip")}
          </Link>
        </div>
        <div
          className={classes.progress}
          style={{ width: `${(stepId > 2 ? 2 : stepId + 1) * 33.33}vw` }}
        />
        {/* <div className={classes.past7days}>
          <span className={classes.past7daysTitle}>
            {" "}
          </span>
        </div> */}
        <div
          id="slides"
          className={classes.slides}
          style={{ paddingBottom: 70 }}
        >
          <div
            id="slideContainer"
            className={classes.slideContainer}
            style={{ maxHeight: `${windowHeight - whOffset + 48}px` }}
          >
            <div id="slide0" className={classes.slide}>
              <img
                style={{ marginTop: "20px" }}
                height="200"
                // width="88"
                src="/images/feedback_image_3.png"
                alt="Family reading"
              />
              <br />
              <div className={classes.text7days}>
                {i18n.t("recordProgress.past7days")}
              </div>
              <span className={classes.textBig}>
                {
                  actionPlanDetail.actionPlan.feedbackContent.questions[0]
                    .question
                }
              </span>
              <br />
              <div id="ReflectButtons" className={classes.buttons}>
                {actionPlanDetail.actionPlan.feedbackContent.questions[0].options.map(
                  (a, i) => {
                    const key = i;
                    return (
                      <button
                        type="button"
                        key={key}
                        className={`${classes.buttonSelect} ${
                          progress === a ? classes.selected : ""
                        }`}
                        onClick={() => this.handleProgressChange(a)}
                      >
                        {a}
                      </button>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          {/* <div className={classes.slideContainer} style={{ maxHeight: `${windowHeight - whOffset + 48}px` }}>
            <div id="slide1" className={classes.slide}>
              <img
                style={{ marginTop: "20px" }}
                height="200"
                // width="88"
                src="/images/feedback_image_3.png"
                alt="Family reading"
              />
              <br />
              <span className={classes.textBig}>
                {i18n.t("recordProgress.howManyDays", {
                  firstName: softLogin.activeChild.firstName
                })}
              </span>
              <br />
              <span className={classes.textSmall}>
                {i18n.t("recordProgress.selectNumberOfDays")}
              </span>
              <DaysSelect
                classes={classes}
                onChange={this.handleInputChange}
                selectedValue={days}
              />
            </div>
          </div>
          <div className={classes.slideContainer} style={{ maxHeight: `${windowHeight - whOffset + 48}px` }}>
            <div id="slide2" className={classes.slide}>
              <img
                style={{ marginTop: "20px" }}
                height="200"
                // width="88"
                src="/images/feedback_image_3.png"
                alt="Family reading"
              />
              <br />
              <span className={classes.textBig}>
                {i18n.t("recordProgress.howManyMinutes", {
                  firstName: softLogin.activeChild.firstName
                })}
              </span>
              <br />
              <span className={classes.textSmall}>
                {i18n.t("recordProgress.selectNumberOfMinutes")}
              </span>
              <MinutesSelect
                classes={classes}
                onChange={this.handleInputChange}
                selectedValue={minutes}
              />
            </div>
          </div> */}
          <div
            className={classes.slideContainer}
            style={{ maxHeight: `${windowHeight - whOffset + 48}px` }}
          >
            <div id="slide3" className={classes.slide}>
              <img
                style={{ marginTop: "20px" }}
                height="200"
                // width="88"
                src="/images/feedback_image_3.png"
                alt="Family reading"
              />
              <br />
              <span className={classes.textBig}>
                {i18n.t("recordProgress.howWasExperience", {
                  firstName: softLogin.activeChild.firstName,
                })}
              </span>
              <br />
              <span className={classes.textSmall}>
                {i18n.t("recordProgress.selectEmoji")}
              </span>
              <br />
              <div id="EmojiContainer" className={classes.emoji}>
                <Link
                  role="button"
                  tabIndex={0}
                  className={emoji === "5" ? classes.emojiSelected : ""}
                  onClick={() => this.handleInputChange("emoji", "5")}
                  onKeyDown={(e) => this.handleEmojiKeyDown(e, "emoji", "5")}
                >
                  <img
                    height="45"
                    src="/images/emoji/5.png"
                    alt="happy emoji"
                  />
                </Link>
                <Link
                  role="button"
                  tabIndex={0}
                  className={emoji === "4" ? classes.emojiSelected : ""}
                  onClick={() => this.handleInputChange("emoji", "4")}
                  onKeyDown={(e) => this.handleEmojiKeyDown(e, "emoji", "4")}
                >
                  <img
                    height="45"
                    src="/images/emoji/4.png"
                    alt="smiling emoji"
                  />
                </Link>
                <Link
                  role="button"
                  tabIndex={0}
                  className={emoji === "3" ? classes.emojiSelected : ""}
                  onClick={() => this.handleInputChange("emoji", "3")}
                  onKeyDown={(e) => this.handleEmojiKeyDown(e, "emoji", "3")}
                >
                  <img
                    height="45"
                    src="/images/emoji/3.png"
                    alt="neutral emoji"
                  />
                </Link>
                <Link
                  role="button"
                  tabIndex={0}
                  className={emoji === "2" ? classes.emojiSelected : ""}
                  onClick={() => this.handleInputChange("emoji", "2")}
                  onKeyDown={(e) => this.handleEmojiKeyDown(e, "emoji", "2")}
                >
                  <img height="45" src="/images/emoji/2.png" alt="sad emoji" />
                </Link>
                <Link
                  role="button"
                  tabIndex={0}
                  className={emoji === "1" ? classes.emojiSelected : ""}
                  onClick={() => this.handleInputChange("emoji", "1")}
                  onKeyDown={(e) => this.handleEmojiKeyDown(e, "emoji", "1")}
                >
                  <img
                    height="45"
                    src="/images/emoji/1.png"
                    alt="crying emoji"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div
            className={classes.slideContainer}
            style={{ maxHeight: `${windowHeight - whOffset + 48}px` }}
          >
            <div id="slide4" className={classes.slide}>
              <img
                style={{ marginTop: "20px" }}
                height="200"
                // width="88"
                src="/images/feedback_image_3.png"
                alt="Family reading"
              />
              <br />
              <span className={classes.textBig}>{fb.text}</span>
              <br />
              <div id="FbContainer" className={classes.buttons}>
                {fb.answers.map((a, i) => {
                  const key = i;
                  return (
                    <button
                      type="button"
                      key={key}
                      className={`${classes.buttonSelect} ${
                        feedback.indexOf(a) !== -1 ? classes.selected : ""
                      }`}
                      onClick={() => this.handleFeedbackChange(a)}
                    >
                      {a}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className={classes.prevNext}>
              <Button
                id="BackRestartButton"
                onClick={
                  stepId <= 1 ? this.handlePrevStep : this.handleResetProgress
                }
                className={`${classes.prev} ${classes.prevNextBtn} ${classes.prevNextBtnActive}`}
              >
                {stepId > 0 && stepId <= 1 ? i18n.t("recordProgress.Back") : ""}
                {stepId > 1 ? i18n.t("recordProgress.Start Over") : ""}
              </Button>
              <Button
                id="DoneButton"
                disabled={!this.stepHasAnswer()}
                onClick={
                  this.isCompleted()
                    ? this.handleSendFeedbackButton
                    : this.handleNextStep
                }
                className={
                  !this.stepHasAnswer()
                    ? `${classes.next} ${classes.prevNextBtn}`
                    : `${classes.next} ${classes.prevNextBtn} ${classes.prevNextBtnActive}`
                }
              >
                {stepId === 2 ? button : i18n.t("Questionnaire.Next")}{" "}
                <img
                  src={`/images/onboarding/btnNext${
                    !this.stepHasAnswer() ? "" : "Active"
                  }.png`}
                  height="30"
                  alt="next icon"
                />
              </Button>
            </div>
          </div>
        </div>
        <Dialog
          disableEscapeKeyDown
          PaperComponent={CustomPaper}
          open={wantToSkip}
          TransitionComponent={TransitionDown}
        >
          <SkipPopup
            handleQuitButton={this.handleQuitButton}
            handleSkipBtn={this.handleSkipBtn}
          />
        </Dialog>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  progress: {
    height: 7,
    background: theme.palette.primary.main,
    transition: "width 500ms",
  },
  slides: {
    height: "calc(100% - 164px)",
    width: "100%",
    overflow: "hidden",
    "scroll-behavior": "smooth",
  },
  slideContainer: {
    overflow: "scroll",
  },
  slide: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: 10,
    width: "100%",
    height: "80vh",
    padding: theme.spacing(3),
    borderBottom: "solid #f6f8fb 10px",
  },
  skipBtn: {
    fontSize: 12,
    fontWeight: 400,
    color: "#ffffff",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
    color: "#ffffff",
    fontSize: 18,
    fontWeight: 700,
  },
  header: {
    background: theme.palette.teacher.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 44,
    padding: theme.spacing(1),
  },
  text7days: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "left",
    marginBottom: 12,
    alignSelf: "start",
  },
  textBig: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22px",
    background: "#F6F6F6",
    boxShadow: "1.52778px 3.05556px 3.81945px rgba(0, 0, 0, 0.15)",
    borderRadius: 6,
    padding: 8,
  },
  textSmall: {
    fontSize: 14,
  },
  past7days: {
    background: "#F6F8FB",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 44,
    padding: theme.spacing(1),
    "& span": {
      flexGrow: 1,
      textAlign: "center",
      color: "#000000",
      fontSize: 18,
      fontWeight: 700,
    },
  },
  select: {
    margin: "30px auto 10px",
  },
  emoji: {
    "& a > img": {
      padding: "5px",
      margin: "5px",
      display: "inline-block",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  buttonSelect: {
    display: "inline-block",
    maxWidth: 180,
    height: "40px",
    marginBottom: 10,
    marginRight: 10,
    padding: "3px 12px",
    background: "#49BFF1",
    boxShadow: "0px 4px 0px #1CAFEE",
    borderRadius: 30,
    color: "#fff",
    border: 0,
    // borderRadius: 10,
    // border: "4px solid #DAE8F2",
    // background: "#E7F5FF",
    // color: "#000000",
    fontSize: 14,
    verticalAlign: "middle",
    fontWeight: 600,
  },
  selected: {
    // border: "4px solid #96C93E",
    boxShadow: "0px 4px 0px #71C285",
    background: "#A3D64B",
    border: 0,
  },
  prevNext: {
    marginTop: "auto",
    paddingTop: 5,
    paddingBottom: 5,
    background: "#F6F8FB",
    position: "absolute",
    width: "100%",
    bottom: 0,
  },
  prevNextBtn: {
    width: "50%",
    border: "none",
    height: 50,
    background: "transparent",
    boxSizing: "border-box",
    padding: 0,
    color: "#c4c6c8",
    display: "inline",
    borderRadius: 0,
    fontSize: 14,
    textTransform: "none",
    fontWeight: 500,
    verticalAlign: "middle",
    lineHeight: "50px",
    "& img": {
      verticalAlign: "middle",
    },
  },
  prevNextBtnActive: {
    color: "#000000",
  },
  prev: {
    textAlign: "left",
    paddingLeft: 20,
    borderRight: "2px solid #BAD2E1",
  },
  next: {
    textAlign: "right",
    paddingRight: 20,
  },
  emojiSelected: {
    "& img": {
      background: "#BAD2E1",
    },
  },
});

const mapStateToProps = (state) => ({
  softLogin: state.softLogin,
  actionPlanDetail: state.actionPlanDetail,
  windowHeight: state.windowHeight,
});

export default withRouter(
  connect(mapStateToProps, { sendReadingLog, saveSoftLogin })(
    withStyles(styles)(RecordProgressV3)
  )
);
