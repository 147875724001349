import { FETCH_STARS_PERCENTAGES } from "../actionTypes";

const PercentagesReducer = (payload = null, action) => {
  if (action.type === FETCH_STARS_PERCENTAGES) {
    return {
      apaId: action.apaId,
      workshop: action.payload.percentage.workshop,
      instruction: action.payload.percentage.instruction,
      record_progress: action.payload.percentage.record_progress,
      reading_time: action.payload.percentage.reading_time
    };
  }
  return payload;
};

export default PercentagesReducer;
