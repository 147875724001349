import React from "react";

const TargetExceededIcon = (props) => {
  const { style } = props;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 294 294"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.2 107.101H47.25C38.4315 107.101 29.9743 103.597 23.7387 97.3618C17.5031 91.1263 14 82.669 14 73.8506C14 65.0321 17.5031 56.5749 23.7387 50.3393C29.9743 44.1037 38.4315 40.6006 47.25 40.6006H67.2M226.8 107.101H246.75C255.568 107.101 264.026 103.597 270.261 97.3618C276.497 91.1263 280 82.669 280 73.8506C280 65.0321 276.497 56.5749 270.261 50.3393C264.026 44.1037 255.568 40.6006 246.75 40.6006H226.8M40.6 280H253.4M120.4 182.378V213.5C120.4 220.815 114.149 226.534 107.499 229.593C91.8049 236.775 80.4999 256.592 80.4999 280M173.6 182.378V213.5C173.6 220.815 179.851 226.534 186.501 229.593C202.195 236.775 213.5 256.592 213.5 280"
        stroke="#EB692E"
        stroke-width="26.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M226.801 14H67.2012V107.1C67.2012 128.264 75.6086 148.562 90.574 163.527C105.539 178.492 125.837 186.9 147.001 186.9C168.165 186.9 188.463 178.492 203.428 163.527C218.394 148.562 226.801 128.264 226.801 107.1V14Z"
        stroke="#EB692E"
        stroke-width="26.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default TargetExceededIcon;
