export const changeLanguageStyles = (theme) => ({
  dialog: {
    zIndex: "1510 !important"
  },
  dialogTitle: {
    padding: 0,
  },
  dialogContent: {
    background: "#ffffff",
    borderRadius: "30px 30px 0 0",
    padding: 16,
    display: "flex",
    flexDirection: "column",
  },
  image: {
    textAlign: "center",
    "& img": {
      paddingBottom: theme.spacing(1),
    },
  },
  panel: {
    backgroundColor: "#F5F5F5",
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    letterSpacing: "0.5px",
  },
  when: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "19px",
    letterSpacing: "0.5px",
  },
  submit: {
    display: "flex",
    flex: 1,
    alignItems: "flex-end"
  },
});
