import React from "react";

const TargetIcon = (props) => {
  const { style } = props;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 404 401"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M177.483 46.0349C154.175 46.0349 131.096 50.6256 109.563 59.545C88.0299 68.4643 68.4643 81.5376 51.9835 98.0184C18.699 131.303 0 176.446 0 223.518C0 270.589 18.699 315.733 51.9835 349.017C68.4643 365.498 88.0299 378.571 109.563 387.491C131.096 396.41 154.175 401.001 177.483 401.001C224.554 401.001 269.698 382.302 302.982 349.017C336.267 315.733 354.966 270.589 354.966 223.518C354.966 202.93 351.239 182.519 344.139 163.351L315.742 191.748C318.227 202.22 319.469 212.869 319.469 223.518C319.469 261.175 304.51 297.29 277.882 323.917C251.255 350.545 215.14 365.504 177.483 365.504C139.826 365.504 103.711 350.545 77.0834 323.917C50.4558 297.29 35.4966 261.175 35.4966 223.518C35.4966 185.861 50.4558 149.746 77.0834 123.118C103.711 96.4907 139.826 81.5315 177.483 81.5315C188.132 81.5315 198.781 82.7739 209.252 85.2586L237.827 56.6839C218.481 49.7621 198.071 46.0349 177.483 46.0349ZM177.483 117.028C149.24 117.028 122.154 128.247 102.183 148.218C82.2126 168.189 70.9932 195.275 70.9932 223.518C70.9932 251.761 82.2126 278.847 102.183 298.817C122.154 318.788 149.24 330.008 177.483 330.008C205.726 330.008 232.812 318.788 252.783 298.817C272.753 278.847 283.973 251.761 283.973 223.518H248.476C248.476 242.346 240.996 260.404 227.683 273.718C214.369 287.031 196.311 294.511 177.483 294.511C158.654 294.511 140.597 287.031 127.283 273.718C113.969 260.404 106.49 242.346 106.49 223.518C106.49 204.689 113.969 186.632 127.283 173.318C140.597 160.004 158.654 152.525 177.483 152.525V117.028Z"
        fill="#006FB6"
      />
      <circle cx="176.506" cy="224.499" r="33.3393" fill="#006FB6" />
      <path
        d="M399.082 52.7245L352.193 5.84642C350.634 4.28718 348.783 3.05031 346.746 2.20644C344.709 1.36256 342.526 0.928223 340.321 0.928223C338.116 0.928223 335.933 1.36256 333.896 2.20644C331.859 3.05031 330.009 4.28718 328.45 5.84642L199.073 135.223C197.508 136.776 196.266 138.625 195.422 140.662C194.577 142.699 194.145 144.884 194.152 147.089V193.978C194.152 198.43 195.921 202.7 199.069 205.848C202.217 208.996 206.487 210.765 210.94 210.765L261.301 205.848C261.301 205.848 270.744 203.421 281.572 193.978L399.082 76.4679C400.641 74.909 401.878 73.0582 402.722 71.0213C403.565 68.9843 404 66.8011 404 64.5962C404 62.3914 403.565 60.2081 402.722 58.1712C401.878 56.1342 400.641 54.2834 399.082 52.7245ZM243.8 172.994L338.585 78.2095L356.096 95.7101L261.301 190.505L243.8 172.994ZM326.687 66.3326L231.924 161.128L214.412 143.616L309.207 48.832L326.687 66.3326ZM210.94 163.876L241.041 193.978H210.94V163.876Z"
        fill="#EB692E"
      />
    </svg>
  );
};

export default TargetIcon;
